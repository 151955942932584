import * as Auth from 'utils/auth';

export function formatCurrency(amt) {
    amt = amt + '';
    var neg = false;
    amt = amt.replace(',', '');
    if (amt < 0) {
        neg = true;
        amt = Math.abs(amt);
    }
    
    return (neg ? "-" : '') + parseFloat(amt, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
}

export function ML(str) {
    return Auth.GetLangLabel(str);
}

export function formatDateTime(date) {
    var d = typeof date == "Date" ? date : new Date(date + ' UTC');
    const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' }
    return new Intl.DateTimeFormat(Auth.EmployeeData('Language'), options).format(d);
}

export function formatDate(date) {
    var d = typeof date == "Date" ? date : new Date(date);
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' }
    return new Intl.DateTimeFormat(Auth.EmployeeData('Language'), options).format(d);
}

export function formatDateToDBString(date, includeTime) {
    var d = typeof date == "Date" ? date : new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    if (includeTime) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        if (hours < 10)
            hours = "0" + hours;

        if (minutes < 10)
            minutes = "0" + minutes;

        if (seconds < 10)
            seconds = "0" + seconds;

        return [year, month, day].join('-') + ' ' + [hours, minutes, seconds].join(':');
    }
    else return [year, month, day].join('-');
}

export function updateJsonObjectWithObject(objFrom, objTo) {
    for (const key in objFrom) {   
        if (key == 'id') continue;
        if (objTo[key] != objFrom[key]) {
            objTo[key] = objFrom[key] || '';
        }
    }
}

export function removeHtml(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str.replace(/<br>/ig, "\n").replace(/<br\/>/ig, '\n').replace(/<br \/>/ig, '\n').replace(/<\/p>/ig, '\n');
    return tmp.textContent || tmp.innerText || "";
}

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function newGuid() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

export function blank(x) {
    return x == undefined || x == null || x == '';
}