import { useState, forwardRef, useRef, useImperativeHandle } from 'react';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import LinearProgress from '@mui/material/LinearProgress';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import * as ApiProcess from 'api/processes';
import * as Helpers from 'utils/ui-helpers'
import * as Auth from 'utils/auth';

import { dateTimeValueFormatter } from 'utils/mui-helpers'

import EvsDraggableDialog, { EvsDialogContent } from 'components/core/EvsDraggableDialog';
import EvsQuillEditor from 'components/core/EvsQuillEditor';
import EvsDataGrid from 'components/core/EvsDataGrid';

let rowsDeleted = [];

const EvsCommentEditPopup = forwardRef(({open, onClose, onSave, ...props}, ref) => {
    useImperativeHandle(ref, () => ({         
      initialize: initialize
    }));  

    const [titleText, setTitleText] = useState('');
    const [defaultValue, setDefaultValue] = useState('');
    const [invoiceComments, setInvoiceComments] = useState([]);
    const [rowEditing, setRowEditing] = useState([]);    
    const [rowChanged, setRowChanged] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [gridLoading, setGridLoading] = useState(true);
    const [deleteEnabled, setDeleteEnabled] = useState(true);
    const quillRef = useRef();
    const gridRef = useGridApiRef();

    const initialize = (value, titleString, invoiceRow) => {
      setTitleText(titleString);
      setDefaultValue(value);
      setShowComments(invoiceRow != undefined);
      setGridLoading(invoiceRow != undefined);
      setInvoiceComments([]);
      setDeleteEnabled(true);
      rowsDeleted = [];

      if (invoiceRow != undefined) {
        ApiProcess.getProcessInvoiceComments(invoiceRow.ProcessId, invoiceRow.Invoice, invoiceRow.WBS1, (data) => {
          setInvoiceComments(data);
          setGridLoading(false);

          if (gridRef.current != null) {
            if (invoiceRow.CommentId != undefined)
              gridRef.current.selectRow(invoiceRow.CommentId, true, true);
            else 
              handleAddComment(data);
          }
        });
      }
    }

    const onLoad = (quill) => {
      quill.clipboard.dangerouslyPasteHTML(0, defaultValue);
      quill.height = 100;
    }

    const handleSave = () => { 
      updateRowComment(); 
      onSave(quillRef.current.getSemanticHTML(), invoiceComments, rowsDeleted); 
      onClose(); 
    }

    const handleOnTextChange = (d, o, s) => {
      if (s == 'user') {
        setRowChanged(true);
      }
    }

    const handleOnRowClick = (e) => { 
      updateRowComment();
      quillRef.current.setContents("");
      quillRef.current.clipboard.dangerouslyPasteHTML(0, e.row.CommentHTML);
      quillRef.current.enable(true);
      setRowEditing(e.row);
      setRowChanged(false);
      setDeleteEnabled(true);
    }

    const handleAddComment = (data) => {
      updateRowComment();

      var newRow = { id: Helpers.newGuid(), CommentId:0, ModDate: new Date(), ModUser: Auth.User(), CommentHTML: '', ModEmployee: Auth.EmployeeData('EMName') };
      if (data != undefined)
        setInvoiceComments([...data, newRow]);
      else
        setInvoiceComments([...invoiceComments, newRow]);

      quillRef.current.setContents("");
      quillRef.current.clipboard.dangerouslyPasteHTML(0, "");
      quillRef.current.enable(true);

      gridRef.current.selectRow(newRow.id, true, true);
      setRowEditing(newRow);
      setRowChanged(false);
    }

    const handleDeleteComment = () => {
      var deleteRow = rowEditing;
      var rows = [...invoiceComments];
      if (deleteRow.CommentId > 0) 
        rowsDeleted.push(deleteRow.CommentId);
      setInvoiceComments(rows.filter(r => r.id != deleteRow.id));

      quillRef.current.setContents("");
      quillRef.current.enable(false);

      setDeleteEnabled(false);
    }

    const updateRowComment = () => {
      if (rowEditing != undefined && rowEditing != [] && rowChanged) {
        rowEditing.CommentHTML = quillRef.current.getSemanticHTML();
        rowEditing.CommentNoHTML = Helpers.removeHtml(rowEditing.CommentHTML);
        rowEditing['modified'] = true;
      }
    }

    return (
        <EvsDraggableDialog
          open={open}                     
          aria-labelledby="dialog-title"          
        >
          <EvsDialogContent onClose={onClose} titleText={titleText} sx={{ height:`${showComments ? '600px' : '380px'}` }}>
            <Box sx={{ display:`${showComments ? 'display' : 'none'}` }}>
              <IconButton id="btnAdd" aria-label="addComment" onClick={handleAddComment} sx={{ position: 'absolute', top:8, right:90, color: '#669966' }}>
                <AddCircleIcon />                    
              </IconButton>
              <IconButton id="btnRemove" aria-label="removeComment" disabled={!deleteEnabled} onClick={handleDeleteComment} sx={{ position: 'absolute', top:8, right:60, color: '#aa6666' }}>
                <DeleteIcon />
              </IconButton>
              <EvsDataGrid rows={invoiceComments} sx={{ display:`${showComments ? 'display' : 'none'}`, height:200 }}
                slots={{ loadingOverlay: LinearProgress }}
                loading={gridLoading}
                apiRef={gridRef} 
                onRowClick={handleOnRowClick}
                columns={[ 
                  { headerName: 'Employee', editable: false, field: 'ModEmployee', width:120 },
                  { headerName: 'Date', editable: false, field: 'ModDate', width:150, type:'dateTime', valueGetter: dateTimeValueFormatter },
                  { headerName: 'Comment', editable: false, field: 'CommentHTML', width:300, valueGetter: (v) => v && Helpers.removeHtml(v) }
                ]}
              />
            </Box>
            <Box sx={{ width:'100%', height:'150px' }}>
              <EvsQuillEditor
                ref={quillRef}                
                onLoad={onLoad}
                onTextChange={handleOnTextChange}
                style={{ height: 245 }}
              />
            </Box>
            <Box>              
              <IconButton
                  id="btnClose"
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      bottom:8,
                      right:60,
                      color: () => '#cc3333',
                  }}
              >
                <CancelIcon />                    
              </IconButton>
              <IconButton
                  id="btnSave"
                  aria-label="save"
                  onClick={handleSave}
                  sx={{
                      position: 'absolute',
                      bottom:8,
                      right:12,
                      color: () => '#339933',
                  }}
              >
                <CheckIcon />
              </IconButton>
            </Box>              
          </EvsDialogContent>
        </EvsDraggableDialog>
    );
});

export default EvsCommentEditPopup;