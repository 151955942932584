const localMemory = {};

const updateJsonCookie = (objectName, jsonObject) => window.sessionStorage.setItem(objectName, JSON.stringify(jsonObject));

export const UpdateObject = (objectName, jsonObject) => {
    updateJsonCookie(objectName, jsonObject);
    localMemory[objectName] = jsonObject;
}

export const UpdateSetting = (objectName, setting, val) => {
    if (localMemory[objectName] == undefined || localMemory[objectName] == null) 
        GetObject(objectName);
    
    localMemory[objectName][setting] = val;    
}

export const GetObject = (objectName) => {
    if (localMemory[objectName] == undefined || localMemory[objectName] == null) {
        var val = window.sessionStorage.getItem(objectName); 
        var json = val != undefined && val != '' ? JSON.parse(val) : null;
        localMemory[objectName] = json;
    }    
    return localMemory[objectName] != undefined ? localMemory[objectName] : null; 
}

export const GetSetting = (objectName, setting) => {
    return (GetObject(objectName)[setting] != undefined ? localMemory[objectName][setting] : ''); 
} 

export const Clear = () => {
    for (const key of Object.keys(localMemory)) 
        delete localMemory[key];    
    window.sessionStorage.clear();
}

