import * as Api from 'api/api';
import * as Auth from 'utils/auth';
import * as Helpers from 'utils/ui-helpers';

const cachedValues = {};

export function getActionStatuses(funcComplete, funcError) {
    if (cachedValues.actionStatuses == undefined) {
        Api.get('config/'+Auth.EmployeeData('Language')+'/action-statuses', null,        
            (data) => { 
                Api.addValueLabel(data, 'Seq', 'ActionDescription');
                cachedValues.actionStatuses = data;
                if (funcComplete != undefined) 
                    funcComplete(data);
            },
            funcError
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.actionStatuses);
}

export function getActionTemplates(funcComplete, funcError) {
    if (cachedValues.actionTemplates == undefined) {
        Api.get('config/'+Auth.EmployeeData('Language')+'/action-templates', null,        
            (data) => { 
                Api.addValueLabel(data, 'RowId', 'ActionTemplateName'); 
                cachedValues.actionTemplates = data;
                if (funcComplete != undefined) 
                    funcComplete(data);
            },
            funcError
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.actionTemplates);    
}

export function getActionTemplatesForActions(funcComplete, funcError) {
    if (cachedValues.actionTemplatesForActions == undefined) {
        getActionTemplates((data) => {
            cachedValues.actionTemplatesForActions = data.filter(x => (x.IsActionTemplate == 'Y' && x.Status == 'A') || x.label == '');
            if (funcComplete != undefined) 
                funcComplete(cachedValues.actionTemplatesForActions); 
        }, funcError);
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.actionTemplatesForActions); 
}

export function getRootCauses(funcComplete, funcError) {
    if (cachedValues.rootCauses == undefined) {
        Api.get('config/'+Auth.EmployeeData('Language')+'/root-causes', null,        
            (data) => { 
                Api.addValueLabel(data, 'Seq', 'RootCause', true); 
                cachedValues.rootCauses = data;
                if (funcComplete != undefined) 
                    funcComplete(data);
            },
            funcError
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.rootCauses);    
}

export function getActionsDueByList(funcComplete, funcError) {
    if (cachedValues.ationsDueByList == undefined) {
        Api.get('config/'+Auth.EmployeeData('Language')+'/actions-due-by', {
                localDateTimeString: Helpers.formatDateToDBString(new Date())
            },        
            (data) => { 
                Api.addValueLabel(data, 'ThruDate', 'MenuItem', false); 
                cachedValues.ationsDueByList = data;
                if (funcComplete != undefined) 
                    funcComplete(data);
            },
            funcError, true
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.ationsDueByList);    
}

export function getCustomColumns(funcComplete, funcError) {
    if (cachedValues.customColumns == undefined) {
        Api.get('config/'+Auth.EmployeeData('Language')+'/custom-columns', null,        
            (data) => { 
                Api.addId(data, 'Seq'); 
                cachedValues.customColumns = data;
                if (funcComplete != undefined) 
                    funcComplete(data);
            },
            funcError
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.customColumns);    
}