import * as Helpers from 'utils/ui-helpers'

export const dateValueFormatter = (v) => v && new Date(typeof v == "object" || v.includes(':00') ? v : (v + 'T00:00:00'));
export const dateTimeValueFormatter = (v) => v && (typeof v == "object" ? new Date(v) : new Date(v + ' UTC'));

export const dateTimeFormatting = () => {
    return { valueGetter: dateTimeValueFormatter, valueFormatter: (v) => Helpers.formatDateTime(v) };
}

export const dateFormatting = () => {
    return { valueGetter: dateValueFormatter, valueFormatter: (v) => Helpers.formatDate(v) };
}

export const setupCustomColumns = (data, type) => {
    var cc = [];
    data.forEach(x => {
      if (x['Enabled'+type] == 'Y' && x.Status == 'A' && x.Hidden == 'N') {
        var ccDef = { headerName: x.Label, field: x.ColumnName, minWidth:(x.Label.length * 5), 
            type: (x.DataType == 'C' || x.DataType == 'N' ? 'number' : 
                x.DataType == 'D' ? 'date' : 
                x.DataType == 'B' ? 'boolean' :                 
                '') };

        if (x.DataType == 'C') ccDef.valueFormatter = (v, r) => v && Helpers.formatCurrency(v);
        if (x.DataType == 'D') ccDef.valueGetter = dateValueFormatter;
        
        cc.push(ccDef);
      }
    });

    return cc;
}