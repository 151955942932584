import { useState, useEffect } from 'react';
import * as React from 'react';

import Box from '@mui/material/Box';
import { gridNumberComparator } from '@mui/x-data-grid-pro';

import * as API from 'api/main'
import EvsDataGrid from 'components/core/EvsDataGrid'
import { formatCurrency } from 'utils/ui-helpers';
import { GetAccessToken, GetUserSetting } from 'utils/auth';

export default function Main() {
  const [gridData, setGridData] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);   

  useEffect(() => {
    if (isInitialized) return;
    async function init() {            
      API.getProcessData('', '', (data) => {
        setIsInitialized(true);
        setGridData(data);
      });
    }
    init();
  }, [gridData, isInitialized]);

  return ( 
    <>
      <Box minWidth={0} component="main" sx={{ flexGrow: 1, p: 3, height:'80%' }}>      
        <Box sx={{ flexGrow: 1, paddingTop: 5 }}>
          Login Successful <br/> <br/>
          <b>Auth Token:</b> {GetAccessToken().substring(0, 100) + '...'} <br/> <br/>
          <b>Tenant Id:</b> {GetUserSetting('tenantId')} <br/><br/>
        </Box>
      
        <EvsDataGrid          
          columns={[ 
            { headerName: 'Process Name', field: 'ProcessName', flex:1.5 },
            { headerName: 'Project', field: 'WBS1', flex:.5 },
            { headerName: 'Client Name', field: 'ClientName', flex:1 },
            { headerName: 'Invoice Balance', field: 'InvoiceBalance', align:'right', headerAlign:'right', type:'string',
              valueFormatter: (v) => '$' + formatCurrency(v), sortComparator: gridNumberComparator },
            { headerName: 'Create Date', field: 'CreateDate', flex:.8, type:'dateTime', valueGetter: (v) => v && new Date(v) },
            { headerName: 'Max Days Old', field: 'MaxDaysOld', type:'number' }
          ]}          
          rows={gridData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100
              },
            },
          }}        
        />                           
      </Box>
    </>
  );
}