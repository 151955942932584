import * as Api from 'api/api';
import { formatDateToDBString } from 'utils/ui-helpers';

export function updateActionField(actionId, columnName, value, otherValue, funcComplete, funcError) {
    if (value instanceof Date) 
        value = formatDateToDBString(value);
    Api.get('action/'+actionId+'/update', { 
        'columnName':   columnName,
        'value':        (value == '<none>' ? '' : value),
        'otherValue':   otherValue },
        funcComplete,
        funcError
    );
}