import React from 'react';
import * as Cache from 'api/cache';
import { Navigate, useLocation } from 'react-router-dom';

const cachedValues = {}

export const Settings = {
    APP_CONFIG: 'config',
    ACCESS_TOKEN: 'access_token',
    EMPLOYEE_DATA: 'EmployeeData',
    ADVANCED_OPTIONS: 'AdvancedOptions',
    LANGUAGE_LABELS: 'LanguageLabels',
    ARM_ROLES: 'ARMRoles'
};

export const UserSettings = {
    USER_NAME: "UserName"
}

export const ConfigSetting = {
    ACCOUNTING_ROLES: 'AcctRoles'
}

export const AdvancedOption = {
    KEY_MY_ACTIONS_ANY_ACTION_CLICKABLE: "MyActionsAnyActionClickable",
    KEY_CLOSED_ACTIONS_ARE_EDITABLE: "ClosedActionsEditable"
}; 

export const EmployeeSetting = {
    EMPLOYEE_ID: 'Employee',
    ROLE: 'Role',
    FULL_RIGHTS: "FullRights"
}

export const ARM_PROCESS_OWNER_ROLE = '_ARMCollectionOwner';

export const GetAdvancedOption = (optionName) => {    
    if ((Cache.GetObject(Settings.ADVANCED_OPTIONS) || '') == '') {
        var optionsObject = {};
        var advOptions = GetAppConfig(Settings.ADVANCED_OPTIONS);
        if (advOptions == undefined || advOptions == '') return '';
        var split = advOptions.split('&');
        split.forEach(x => {
            var keyValue = x.split('=');
            optionsObject[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : '';
        });

        var userAdvOptions = GetUserRoleSettings()['AdvancedOptionsOverride'];
        if (userAdvOptions != undefined && userAdvOptions != '') {
            var split2 = userAdvOptions.split('&');
            split2.forEach(x => {
                var keyValue = x.split('=');
                optionsObject[keyValue[0]] = keyValue.length > 1 ? keyValue[1] : '';
            });
        }

        Cache.UpdateObject(Settings.ADVANCED_OPTIONS, optionsObject);
    }

    return Cache.GetSetting(Settings.ADVANCED_OPTIONS, optionName);
}

export const SetLanguageLabels = (jsonObject) => {
    var newJson = {};
    jsonObject.forEach(x => { newJson[x.Id] = x.Label; });

    Cache.UpdateObject(Settings.LANGUAGE_LABELS, newJson);
}

export const ClearLoginInfo = () => {
    var databaseName = GetUserSetting('database');
    var BaseUrl = GetUserSetting('BaseUrl');    
    var tenantId = GetUserSetting('tenantId');
    var erpIntegrationSettingsId = GetUserSetting('erpIntegrationSettingsId');

    Cache.Clear();
    if (databaseName || '' != '') {
        SetUserSetting("database", databaseName);
        SetUserSetting("BaseUrl", BaseUrl);
        SetUserSetting("tenantId", tenantId);
        SetUserSetting("erpIntegrationSettingsId", erpIntegrationSettingsId);
    }
}

export const GetRoleFromName = (name) => { 
    var roles = Cache.GetObject(Settings.ARM_ROLES);
    var match = null;
    roles.forEach(x => {
        if (x.Role == name)
            match = x;
    });
    return match;
}

export const GetUserRoleSettings = () => { 
    if (cachedValues.UserRole || '' == '') {
        cachedValues.UserRole = GetRoleFromName(Role());
    }
    return cachedValues.UserRole;
}

export const GetProcessOwnerRole = () => { 
    if (cachedValues.ProcessOwnerRole || '' == '') {
        cachedValues.ProcessOwnerRole = GetRoleFromName(ARM_PROCESS_OWNER_ROLE);
    }
    return cachedValues.ProcessOwnerRole;
}

export const IsAuthenticated = () => !!GetAccessToken();
export const GetUserSetting = (setting) => window.sessionStorage.getItem(setting) || '';
export const GetUserSettingJson = (objectName, setting) => Cache.GetSetting(objectName, setting);
export const GetAppConfig = (setting) => Cache.GetSetting(Settings.APP_CONFIG, setting);
export const GetLangLabel = (setting) => (Cache.GetSetting(Settings.LANGUAGE_LABELS, setting) || setting);
export const GetAccessToken = () => GetUserSetting(Settings.ACCESS_TOKEN);
export const SetUserSetting = (setting, val) => window.sessionStorage.setItem(setting, val); 
export const SetUserSettingJson = (objectName, jsonObject) => Cache.UpdateObject(objectName, jsonObject); 
export const SetAppConfig = (jsonObject) => Cache.UpdateObject(Settings.APP_CONFIG, jsonObject);
export const SetAccessToken = (token) => SetUserSetting(Settings.ACCESS_TOKEN, token); 
export const SetARMRoles = (jsonObject) => Cache.UpdateObject(Settings.ARM_ROLES, jsonObject);

export const EmployeeData = (setting) => GetUserSettingJson(Settings.EMPLOYEE_DATA, setting);
export const Role = () => EmployeeData(EmployeeSetting.ROLE);
export const Employee = () => EmployeeData(EmployeeSetting.EMPLOYEE_ID);
export const User = () => GetUserSetting(UserSettings.USER_NAME);
export const FullRights = () => (EmployeeData(EmployeeSetting.FULL_RIGHTS) === 'Y');

const AuthRoute = function({ children, loginPath }) {
    const prevLocation = useLocation();
    
    if (loginPath == null) 
        loginPath = "/";

    return IsAuthenticated() ?
        children :
        <Navigate to={loginPath+'?redirectTo='+prevLocation.pathname} />;    
};

export default AuthRoute;