import { forwardRef } from 'react';
import Draggable from 'react-draggable';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

function DraggableDialog(props) {
  return (        
    <Draggable
      handle="#dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}        
    >
      <Paper {...props} />
    </Draggable>      
  );
}

export const EvsDialogContent = forwardRef(({titleText, onClose, ...props}, ref) => {
  return (
    <>
      <DialogTitle id="dialog-title" sx={{ m: 0, pt:2, height:'51px', maxheight:'51px !important', fontSize:'1rem' }}>
        { titleText.length < 65 ? titleText : titleText?.substring(0, 65) + "..." }
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right:12,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent 
        dividers 
        sx={{ pt:1, height:'40vh', flexDirection:'column' }} 
        {...props} />
    </>    
  );
});

const EvsDraggableDialog = forwardRef(({open, onClose, titleText, filterText, filterCheckedFunc, filterUncheckedFunc, title, ...props}, ref) => {
    return (
        <Dialog
          PaperComponent={DraggableDialog}
          open={open}                     
          tabIndex={-1}                
          aria-labelledby="dialog-title"
          fullWidth={true}          
          {...props}
        />
    );
});

export default EvsDraggableDialog;