import * as Api from 'api/api';

const cachedValues = {};

export function getActiveEmployees(funcComplete, funcError) {
    if (cachedValues.employees == undefined) {
        Api.get('employees', null,   
            (data) => {
                Api.addValueLabel(data, 'Employee', 'EmployeeName', true);
                cachedValues.employees = data;
                if (funcComplete != undefined) funcComplete(data);                
            },
            funcError
        );
    }
    else if (funcComplete != undefined) funcComplete(cachedValues.employees);
}