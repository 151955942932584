import * as React from 'react';
import { styled, createTheme } from '@mui/material/styles';
import { Accordion, Typography, accordionClasses } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const theme = createTheme({
    palette: {
      primary: {
        main: '#979a9b',
      },
      secondary: {
        main: '#f44336',
      }
    },
    typography: {
      fontSize:10
    }
  });

const EvsAccordion = React.forwardRef(({...props}, ref) => {
    return (        
        <Accordion 
            theme={theme}
            disableGutters
            defaultExpanded
            {...props}
        />
    )
});

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      {...props}
    />
  ))(({ theme }) => ({  
    [`& .${accordionClasses.expanded}`]: { 
      fontSize:'1rem',
    },
    fontSize:'1rem',
    paddingTop: 2,
    marginTop: 0,
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .2)'
}));

export const EvsAccordionSummary = (props) => {
    return (        
        <AccordionSummary  
            sx={{
                height:'30pt',
                minHeight:'30pt'                
            }}          
            expandIcon={<ExpandMoreIcon />}
            {...props}
        />
    )
}

export const EvsAccordionTitle = (props) => {
    return (
        <Typography 
            variant="subtitle1"
            {...props}
        />
    )
}

export default EvsAccordion;