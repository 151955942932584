import { useMemo } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CommentIcon from '@mui/icons-material/Comment';
import { GridActionsCellItem, useGridApiRef, useGridApiContext } from '@mui/x-data-grid-pro';
import Popover from '@mui/material/Popover';
import { Typography } from '@mui/material';

import { ReactComponent as LightningIcon } from 'img/lightning.svg';

import EvsMultiSelectPopup from 'components/core/EvsMultiSelectPopup'
import EvsCommentEditPopup from 'components/EvsCommentEditPopup'
import EvsDataGrid, { multilineGridStyles } from 'components/core/EvsDataGrid'
import { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react';

import * as ApiProcess from 'api/processes'
import * as ApiEmployee from 'api/employee'
import * as ApiActions from 'api/actions'
import * as ApiConfig from 'api/config'
import * as UserRights from 'utils/user-rights'
import * as Helpers from 'utils/ui-helpers'
import * as MHelpers from 'utils/mui-helpers'

const EvsProcessActions = forwardRef(({updateactionrow, rowclick, ...props}, ref) => {
    useImperativeHandle(ref, () => ({         
        loadProcessActions: loadProcessActions,
        setProcessActionsGridLoading: setProcessActionsGridLoading
    }));
    
    const [largeSize, setLargeSize] = useState(Helpers.getWindowDimensions().width > 900);
    const [processActionsGridLoading, setProcessActionsGridLoading] = useState(true);
    const [processActionsGridData, setProcessActionsGridData] = useState([]);
    const [employeesList, setEmployeesList] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    const [statusesList, setStatusesList] = useState([]);
    const [actionTemplatesList, setActionTemplatesList] = useState([]);
    const [fieldName, setFieldName] = useState([]);
    const [customColumnsDefinitions, setCustomColumnsDefinitions] = useState([]);
    const [employeesMultiSelectVisible, setEmployeesMultiSelectVisible] = useState(false);
    const [contactsMultiSelectVisible, setContactsMultiSelectVisible] = useState(false);
    const [editActionCommentVisible, setEditActionCommentVisible] = useState(false);
    const [popupContext, setPopupContext] = useState([]);
    const [parentRow, setParentRow] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowHover, setRowHover] = useState([]);
      
    const actionsRef = useGridApiRef();
    const otherEmployeesMultiSelectRef = useRef();
    const otherContactsMultiSelectRef = useRef();
    const editActionCommentsRef = useRef();
    const contactsHoverOpen = Boolean(anchorEl);

    async function loadProcessActions(newParentRow, contactsListFromParent) {
        if (newParentRow.ProcessId === parentRow.ProcessId) {
            if (actionsRef.current != null) actionsRef.current.selectRow(newParentRow.ActionId, true, true);
            return;
        }

        setParentRow(newParentRow);
        setProcessActionsGridLoading(true);
        setProcessActionsGridData([]);
        setContactsList(contactsListFromParent);

        ApiEmployee.getActiveEmployees(setEmployeesList); 
        ApiConfig.getActionStatuses(setStatusesList);
        ApiConfig.getActionTemplatesForActions(setActionTemplatesList);
        ApiConfig.getCustomColumns((data) => { setCustomColumnsDefinitions(MHelpers.setupCustomColumns(data, 'Action')); });

        ApiProcess.getProcessActionsData(newParentRow.ProcessId, (data) => {   
            setProcessActionsGridLoading(false);
            setProcessActionsGridData(data);
            if (actionsRef.current != null) actionsRef.current.selectRow(newParentRow.ActionId, true, true);
        });
    }

    const handlePopoverContactOpen = (event) => {
        const field = event.currentTarget.dataset.field;
        const id = event.currentTarget.parentElement.dataset.id;
    
        if (field == 'ContactId') {
          var row = processActionsGridData.filter((r) => r.id == id)[0];
          if (row != undefined && (row['Client.Phone'] != undefined || row['Email4'] != undefined)) {
            setRowHover(row);
            setAnchorEl(event.currentTarget);
          }
        }
    };  

    const handlePopoverContactClose = () => {    
        setAnchorEl(null);
    };

    const handleStartActionClick = (id) => () => { }
    const handleOnRowClick = (row) => { rowclick(row); }

    const handleUpdateRow = (updatedRow) => {
        var contact = contactsList.filter(x => x.ContactId == updatedRow['ContactId']);
        updateactionrow(updatedRow, contact);
    }

    const handleClickEditOtherEmployees = (row) => () => {
        otherEmployeesMultiSelectRef.current.initialize(row.OtherEmployees, `Other Employees: Action ${row.ActionName}`, employeesList.filter(x => x.label != ''));
        setPopupContext(row);
        setEmployeesMultiSelectVisible(true);
    }
    const handleSaveOtherEmployees = (values) => {
        popupContext.OtherEmployees = values.map(x => x.Employee).join(',');
        popupContext.OtherEmployeesNames = values.map(x => x.EmployeeName).join('; ');
        ApiActions.updateActionField(popupContext.ActionId, 'OtherEmployees', popupContext.OtherEmployees, '');
    }

    const handleClickEditOtherContacts = (row) => () => {
        otherContactsMultiSelectRef.current.initialize(row.OtherContacts, `Other Contacts: Action ${row.ActionName}`, contactsList.filter(x => x.label != ''));
        setPopupContext(row);
        setContactsMultiSelectVisible(true);
    }
    const handleSaveOtherContacts = (values) => {
        popupContext.OtherContacts = values.map(x => x.ContactId).join(',');
        popupContext.OtherContactsNames = values.map(x => x.ContactName).join('; ');
        ApiActions.updateActionField(popupContext.ActionId, 'OtherContacts', popupContext.OtherContacts, '');
    }

    const handleClickActionComment = (row) => () => {
        setEditActionCommentVisible(true);
        setPopupContext(row);
        editActionCommentsRef.current.initialize(row?.CommentHTML || '', `Comments for Action "${row.ActionName}"`);
    }
    const handleSaveActionComment = (htmlComment) => {
        ApiProcess.upsertProcessComments(parentRow.ProcessId, popupContext.ActionId, null, null, popupContext.CommentId, htmlComment, null, 
            (data) => {
                popupContext.CommentId = data.CommentId;
            }
        );
        
        popupContext.CommentHTML = htmlComment;
        popupContext.CommentNoHTML = htmlComment;
    }

    const RenderActions = ({id, row}) => [ 
        <GridActionsCellItem icon={<LightningIcon width={16} className={(UserRights.MyActionsCellDisabled('(Action)', row) ? 'disabledGridIcon' : '')} />} 
            label="Edit" color="inherit" onClick={handleStartActionClick(id)} 
            disabled={UserRights.MyActionsCellDisabled('(Action)', row)}
        />
    ]
    const RenderOtherEmployees = (params) => {
        var enabled = UserRights.ActionCellEditable({ field: 'OtherEmployeesNames', row: params.row }, parentRow);
        return (<div>
                <IconButton className={'btnOtherEmployees' + (!enabled ? ' disabledGridIcon' : '')} sx={{ fontSize:'1em !important', padding:0, color:'#000000 !important' }} 
                    aria-label="Edit Other Employees" disabled={!enabled} onClick={handleClickEditOtherEmployees(params.row)}
                >
                    {(params.value || '') != '' ? <div style={{ textOverflow:'ellipsis' }}>{params.value}</div> : 
                    <PersonAddIcon sx={{ color:"Gray", fontSize:14 }} />}
                </IconButton>
            </div>);
    }
    const RenderOtherContacts = (params) => {
        var enabled = UserRights.ActionCellEditable({ field: 'OtherContactsNames', row: params.row }, parentRow);
        return (<div>
                <IconButton className={'btnOtherContacts' + (!enabled ? ' disabledGridIcon' : '')} sx={{ fontSize:'1em !important', padding:0, color:'#000000 !important' }} 
                    aria-label="Edit Other Contacts" disabled={!enabled} onClick={handleClickEditOtherContacts(params.row)}
                >
                    {(params.value || '') != '' ? <div>{params.value}</div> : 
                <PersonAddIcon sx={{ color:"Gray", fontSize:14 }} />}
                </IconButton>
            </div>);
    }

    const RenderComments = (params) => {
        var enabled = UserRights.ActionCellEditable({ field: 'CommentHTML', row: params.row }, parentRow);
        return (<div><IconButton className={'btnActionComment' + (!enabled ? ' disabledGridIcon' : '')} sx={{ fontSize:'1em !important', padding:0, color:'#000000 !important' }} 
                    aria-label="Edit Action Comment" disabled={!enabled} onClick={handleClickActionComment(params.row)}
                >
                    {(params.value || '') != '' ? <div>{Helpers.removeHtml(params.value)}</div> : 
                <CommentIcon sx={{ color:"Gray", fontSize:14 }} />}
                </IconButton>
            </div>);
    }

    const ActionsColumns = useMemo(
        () => [ 
            { headerName: 'Action Name', editable: true, field: 'ActionName', width:300 },
            { headerName: '', sortable: false, filterable:false, hideable: false, disableColumnMenu:true, type: 'actions', field:'(Action)', width:30, getActions: RenderActions },
            { headerName: 'Action Template', editable: true, type: 'singleSelect', minWidth:120, field: 'ActionTemplateRowId',  
                valueOptions: actionTemplatesList, valueGetter: (v) => (v || '') },
            { headerName: 'Status', editable: true, type: 'singleSelect', field: 'ActionStatusId', minWidth:80, valueOptions: statusesList },
            { headerName: 'Date Scheduled', editable: true, field: 'DateScheduled', minWidth:90, type:'date', valueGetter: MHelpers.dateValueFormatter,
                cellClassName: (r) => (r.value <= new Date() ? 'cellActionDue' : '')
            },
            { headerName: 'Date Completed', editable: true, field: 'DateCompleted', minWidth:90, type:'date', valueGetter: MHelpers.dateValueFormatter },
            { headerName: 'Employee', editable: true, field: 'Employee', minWidth:130, type: 'singleSelect', valueOptions: employeesList, valueGetter: (v) => (v || '<none>') },
            { headerName: 'Contact', editable: true, field: 'ContactId', minWidth:130, type: 'singleSelect', valueOptions: contactsList, valueGetter: (v) => (v || '<none>') },                
            { headerName: 'Other Employees', editable: true, field:'text', field: 'OtherEmployeesNames', minWidth:100, renderCell: RenderOtherEmployees },
            { headerName: 'Other Contacts', editable: true, field: 'OtherContactsNames', minWidth:100, renderCell: RenderOtherContacts },
            { headerName: 'Project', field: 'WBS1', minWidth:80 },
            { headerName: 'Comments', field: 'CommentHTML', width:300, renderCell: RenderComments },
            ...customColumnsDefinitions
        ]
    );
    
    return (
        <>
        <EvsMultiSelectPopup 
            ref={otherEmployeesMultiSelectRef}
            title="Other Employees"
            open={employeesMultiSelectVisible}                       
            onSave={handleSaveOtherEmployees}
            onClose={() => setEmployeesMultiSelectVisible(false)} 
            filterUncheckedFunc={(x) => x.Active == 'Y' && x.label != ''}
            filterCheckedFunc={(x) => x.label != ''}
            filterText="Include Inactive"
        />
        <EvsMultiSelectPopup 
            ref={otherContactsMultiSelectRef}
            title="Other Contacts"
            open={contactsMultiSelectVisible} 
            onSave={handleSaveOtherContacts}
            onClose={() => setContactsMultiSelectVisible(false)} 
            filterText=""
        />
        <EvsCommentEditPopup 
            ref={editActionCommentsRef}
            title="Edit Action Comment"
            open={editActionCommentVisible} 
            onSave={handleSaveActionComment}
            onClose={() => setEditActionCommentVisible(false)} 
            filterText=""
        />

        <Popover
            sx={{ pointerEvents: 'none' }}
            open={contactsHoverOpen}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'left', }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left', }}
            onClose={handlePopoverContactClose}
            disableRestoreFocus
        >
            <Typography sx={{ p: 1, fontSize:'0.75rem' }}>
                {`${rowHover != undefined ? rowHover['Client.Phone'] || '' : ''}`}<br/>
                {`${rowHover != undefined ? rowHover['EMail4'] || '' : ''}`}</Typography>
        </Popover>

        <EvsDataGrid        
            slots={{ loadingOverlay: LinearProgress }}
            id="actionsGrid"
            parentRow={parentRow}
            headerFilters={largeSize}
            apiRef={actionsRef} 
            loading={processActionsGridLoading}          
            sx={{
                height:'43vh',
                mb:1,
                p:0,
                ...multilineGridStyles
            }}
            columns={ActionsColumns}
            isCellEditable={(cell) => UserRights.ActionCellEditable(cell, parentRow)}
            slotProps={{
                cell: {
                    onMouseEnter: handlePopoverContactOpen,
                    onMouseLeave: handlePopoverContactClose
                }
            }}
            onRowClick={handleOnRowClick}
            rows={processActionsGridData}
            onCellEditStop={(params, event) => {
                setFieldName(params.field);
            }}
            processRowUpdate={(updatedRow, originalRow) => {
                handleUpdateRow(updatedRow);
                if (fieldName == 'ActionTemplateRowId')
                    ApiActions.updateActionField(updatedRow.ActionId, 'ActionTemplateId', updatedRow[fieldName].substring(0, updatedRow[fieldName].length - 1), '');
                else
                    ApiActions.updateActionField(updatedRow.ActionId, fieldName, updatedRow[fieldName], '');
                return updatedRow;
            }}
            onProcessRowUpdateError={(error) => { console.log(error); }}            
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    "WBS1": largeSize,
                    "CommentHTML": largeSize
                  }
                }
            }}
            {...props}
        />
        </>
    );
});

export default EvsProcessActions;