import axios from 'axios';
import * as Auth from 'utils/auth';

export function addId(data, fieldToMapToId) {
    if (data == undefined || data.length == 0) return;
    data.forEach(element => {
        element.id = element[fieldToMapToId];
    });
}

export function addValueLabel(data, fieldToMapToValue, fieldToMapToLabel, addBlankOption) {
    if (data == undefined) return;
    if (addBlankOption === true) {
        var item = {};
        item[fieldToMapToValue] = '<none>';
        item[fieldToMapToLabel] = '';
        data.unshift(item);
    }
    data.forEach(element => {
        element.value = element[fieldToMapToValue] || '';
        element.label = element[fieldToMapToLabel] || '';
    });
}

export function get(route, queryStringParams, funcComplete, funcError, addUserNameParam) {
    if (addUserNameParam) {
        queryStringParams.userName = Auth.User();
    }

    var queryString = '';
    if (queryStringParams != undefined) {
        for (var key in queryStringParams) {
            if (queryString != '') queryString += '&';
            queryString += key + '=' + queryStringParams[key];
        }
    }

    axios.get(process.env.REACT_APP_PRIVATE_API_URL+'/'+route+'?'+queryString, {
        timeout: 1000*60*10,    /* ten minutes */
        headers: {
            'evs-token': 'Bearer ' + Auth.GetAccessToken(),
            'evs-base-url': Auth.GetUserSetting('BaseUrl'),
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    }).then(response => {        
      if (funcComplete != undefined) funcComplete(response.data);
    }).catch(err => {
      console.error(err);
      if (funcError != undefined) funcError(err);
    });
}

export function post(route, bodyParams, funcComplete, funcError, addUserNameParam) {
    if (addUserNameParam) {
        bodyParams.UserName = Auth.User();
    }

    axios.post(process.env.REACT_APP_PRIVATE_API_URL+'/'+route, bodyParams, {
        timeout: 1000*60*10,    /* ten minutes */
        headers: {
            'evs-token': 'Bearer ' + Auth.GetAccessToken(),
            'evs-base-url': Auth.GetUserSetting('BaseUrl'),
            'x-api-key': process.env.REACT_APP_API_KEY
        }
    }).then(response => {        
      if (funcComplete != undefined) funcComplete(response.data);
    }).catch(err => {
      console.error(err);
      if (funcError != undefined) funcError(err);
    });
}