import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import queryString from 'query-string'

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import * as Auth from 'utils/auth';
import * as ApiLogin from 'api/login'
import * as ApiUser from 'api/user'

import { ReactComponent as ARMLogoLarge } from 'img/ARMLogoLarge.svg'

const defaultTheme = createTheme();

export default function SignIn() {
  const location = useLocation();
  const nav = useNavigate();
  const [invalidLoginHidden, setInvalidLoginHidden] = useState(true);
  const [isInitialized, setIsInitialized] = useState(false); 
  const [isLoading, setIsLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);  
  const { redirectTo } = queryString.parse(location.search);
  const { database } = queryString.parse(location.search);

  useEffect(() => {
    async function init() {      
      var settingsId = Auth.GetUserSetting('erpIntegrationSettingsId');

      if (isInitialized || isLoading) 
        return;   

      if (settingsId != null) {
        if (Auth.GetUserSetting('database')?.toLowerCase() == database?.toLowerCase()) {
          setIsInitialized(true);
          nav(redirectTo ?? "/Main");
          return;
        }
        else {
          Auth.ClearLoginInfo();
          if (Auth.GetUserSetting('database') != undefined && database == undefined) {
            setIsLoading(false);
            setIsInitialized(true); 
            return;
          }
          else {
            setSubmitDisabled(true);
            Auth.SetUserSetting('erpIntegrationSettingsId', '');
            Auth.SetUserSetting('tenantId', '');
          }
        }
      }    

      setIsLoading(true);
            
      ApiLogin.getConnection(location, (response) => {
        Auth.SetUserSetting('BaseUrl', response.data.baseUrl);
        setIsLoading(false);
        setIsInitialized(true); 
        setSubmitDisabled(false);
      }, () => {
        setIsLoading(false);
        setIsInitialized(true);
        setSubmitDisabled(true);
      });
    }
    init();
  }, [isInitialized, isLoading, location]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isInitialized || isLoading) return;

    const data = new FormData(event.currentTarget);
    
    setSubmitDisabled(true);

    ApiLogin.getToken(data.get('username'), data.get('password'), () => {
      Auth.SetUserSetting(Auth.UserSettings.USER_NAME, data.get('username'));
      ApiUser.getUserEmployeeInfo(() => {
        nav(redirectTo ?? "/Main");
      });       
    }, (err) => {
      if (err.code === "ERR_BAD_REQUEST") {
        setSubmitDisabled(false);
        setInvalidLoginHidden(false);
      }
    });
  };

  return (    
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" sx={{ flexDirection: 'row', display:'flex', alignItems: 'center', alignContent: 'center' }}>
        <CssBaseline />
        <Box 
        sx={{
            marginBottom:20,
            width:'340px',
            height:'auto',
            color:'#ffffff',             
            padding: 3,
            marginLeft:'auto',
            flexGrow:0,
            flexShrink: 1
          }}
        >
          <Typography variant='h6'>Welcome Back to<br/>A/R Management & Collections</Typography>
          <Box sx={{ backgroundColor:'#B90000', width:'130px', height:'9px', borderRadius:3, my:1 }}></Box>
          <Typography variant='subtitle2'>Sign in to continue to your account</Typography>
        </Box>
        <Box
          className="loginForm"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: '#D9D9D9',
            borderRadius:'46px',
            width:'440px',
            minHeight:'450px',
            height:'70%',
            flexGrow:0,
            flexShrink:1,
            marginRight:'auto',
            px:8, 
            py:6
          }}
        >           
          <Grid container justifyContent={'center'} spacing={2}>
            <Grid item><ARMLogoLarge /></Grid>
          </Grid>
                  
          <Typography component="h6" className="App-error" hidden={invalidLoginHidden}>
            Invalid Login - Try again
          </Typography>
          
          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            className='inputFieldset'
            noValidate            
            sx={{ mt: 1, height:'100%', display:'flex', flexDirection:'column', width:'100%' }}
          >
            <Box sx={{ height:'75px', mt:2 }}>
              <TextField
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                autoFocus
              />
            </Box>
            <Box sx={{ height:'75px', mt:2 }}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
            </Box>
            <Box sx={{ height:'100%', my:'auto', position:'relative', textAlign:'center' }}>
              <Button                
                id="loginButton"
                type="submit"
                disabled={submitDisabled}
                variant="contained"                
                sx={{ mb:0, backgroundColor:'#212B36', position:'absolute', bottom:'0px', mx:'auto', left:'calc(50% - 110px)', width:"220px" }}
              >
                Log In
              </Button>
            </Box>
          </Box>
        </Box>        
      </Container>
    </ThemeProvider>
  );
}