import * as Api from 'api/api';
import { formatDateToDBString } from 'utils/ui-helpers';

const cachedValues = {};

export function getProcessActionsData(processId, funcComplete, funcError) {
    Api.get('processes/'+processId+'/actions', null,
        (data) => { 
            Api.addId(data, 'ActionId'); 
            if (funcComplete != undefined) funcComplete(data);
        },
        funcError
    );
}

export function getProcessComments(processId, funcComplete, funcError) {
    Api.get('processes/'+processId+'/comments', null,
        (data) => { 
            Api.addId(data, 'CommentId'); 
            if (funcComplete != undefined) funcComplete(data);
        },
        funcError
    );
}

export function getProcessInvoices(processId, funcComplete, funcError) {
    Api.get('processes/'+processId+'/invoices', { },
        (data) => { 
            Api.addId(data, 'Invoice'); 
            if (funcComplete != undefined) funcComplete(data);
        },
        funcError,
        true
    );
}

export function updateProcessInvoiceField(processId, invoice, wbs1, columnName, value, funcComplete, funcError) {
    if (value instanceof Date) 
        value = formatDateToDBString(value);
    Api.get('processes/'+processId+'/invoices/'+invoice+'/update', { 
        'columnName':   columnName,
        'value':        (value == '<none>' ? '' : value),
        'wbs1':         wbs1 },
        funcComplete,
        funcError
    );
}

export function getProcessInvoiceComments(processId, invoice, wbs1, funcComplete, funcError) {
    Api.get('processes/'+processId+'/invoices/'+invoice+'/comments', { 
        'wbs1':         wbs1 },
        (data) => { 
            Api.addId(data, 'CommentId'); 
            if (funcComplete != undefined) funcComplete(data);
        },
        funcError, true
    );
}

export function upsertProcessComments(processId, actionId, invoice, wbs1, commentId, comment, commentsCsv, funcComplete, funcError) {
    Api.post('processes/'+processId+'/comments', { 
        'ActionId':   actionId,
        'Invoice':    invoice,
        'WBS1':       wbs1,
        'CommentId':  commentId,
        'Comment':    comment,
        'CommentsCSV': commentsCsv },
        funcComplete,
        funcError, true
    );
}

export function getProcessContacts(processId, funcComplete, funcError) {
    if (cachedValues.contacts == undefined || cachedValues.contacts[processId] == undefined) {
        if (cachedValues.contacts == undefined) cachedValues.contacts = {};
        cachedValues.contacts[processId] = [];

        Api.get('processes/'+processId+'/contacts', {
            'includeContactTitles': 'false'
            },
            (data) => {             
                Api.addValueLabel(data, 'ContactId', 'ContactName', true); 
                if (cachedValues.contacts == undefined) cachedValues.contacts = {};
                cachedValues.contacts[processId] = data;
                if (funcComplete != undefined) funcComplete(data);
            },
            funcError,
            true
        );
    }
    else if (funcComplete != undefined) {
        funcComplete(cachedValues.contacts[processId]);
    }
}