import * as Auth from 'utils/auth'
import * as Helpers from 'utils/ui-helpers'

const cachedValues = {};

export const AccountingRole = () => {
    if (cachedValues['userIsAccountingRole'] == undefined) {
        cachedValues['userIsAccountingRole'] = (';' + Auth.GetAppConfig(Auth.ConfigSetting.ACCOUNTING_ROLES) + ';').includes(Auth.Role());
    }
    return cachedValues['userIsAccountingRole'];
}

const IsUserRoleInRolesList = (dbValueRolesList, isActionEmp, isProcessOwner) => {
    return (", " + dbValueRolesList + ",").includes(", " + Auth.Role() + ",")
        || (AccountingRole() && (", " + dbValueRolesList + ",").includes(", $Accounting,"))
        || (!AccountingRole() && (", " + dbValueRolesList + ",").includes(", $NonAccounting,"))
        || (isActionEmp && (", " + dbValueRolesList + ",").includes(", Action Employee,"))
        || (isProcessOwner && (", " + dbValueRolesList + ",").includes(", Process Owner,"));
}

const CanEditInvoiceComment = (commentUser, commentUserRole, isProcessOwner) => CanEditComment(commentUser, commentUserRole, isProcessOwner, false, '');

const CanEditComment = (commentUser, commentUserRole, isProcessOwner, requireDeleteRights = false, actionEmployee = '') => {
    var userRoleName = Auth.Role();
    var userRole = Auth.GetUserRoleSettings();

    if (commentUser == null)
    {
        commentUser = Auth.User();
        commentUserRole = userRoleName;
    }
    if (userRole == null) return false;

    var commentModificationCode = userRole.CommentModification;

    if (isProcessOwner)
    {
        var ownerRole = Auth.GetProcessOwnerRole();
        if (ownerRole != null && ownerRole.CommentModification > commentModificationCode) 
            commentModificationCode = ownerRole.CommentModification;
    }

    if (actionEmployee == Auth.Employee() && Helpers.blank(commentUser) && commentModificationCode != 0)
        return true;

    switch (commentModificationCode)
    {
        case 0: return false;
        case 1: return commentUser == Auth.User();
        case 2: return commentUserRole == userRoleName || commentUser == Auth.User();
        case 3: return !requireDeleteRights;
        case 4: return true;
    }
    return false;
}

const IsProcessOwner = (parentRow) => {
    var processOwner = parentRow['ProcessOwner'];        
    var isProcessOwner = processOwner == Auth.Employee();
    return isProcessOwner;
}

export const MyActionsCellDisabled = (cellName, row) => {
    if (cellName == '(Action)') {
        if ((row["ActionTemplateId"] || '') == '' && (row["ActionTemplateRowId"] || '') == '') {
            return true;
        }
        else if (Auth.FullRights() || Auth.GetAdvancedOption(Auth.AdvancedOption.KEY_MY_ACTIONS_ANY_ACTION_CLICKABLE) === 'Y') {
            return false;
        }
        
        return (row['Employee'] || row['ActionEmployee']) != Auth.Employee();
    }
}

export const AddCellClasses = (gridId, cell, parentRow) => {    
    if (gridId == 'actionsGrid' && cell.colDef.editable === true) {
        return ActionCellClassAdds(cell, parentRow);
    }
    else if (gridId == 'invoicesGrid' && cell.colDef.editable === true) {
        return InvoiceCellClassAdds(cell, parentRow);
    }
    return '';
}

export const InvoiceCellClassAdds = (cell, parentRow) => InvoiceCellEditable(cell, parentRow) ? '' : 'disabledGridCell';

export const InvoiceCellEditable = (cell, parentRow) => {
    if (cell.field == 'CommentNoHTML' || cell.field == 'CommentHTML') {
        var isProcessOwner = IsProcessOwner(parentRow);
        return CanEditInvoiceComment(null, null, isProcessOwner);
    }
    return true;
}

export const ActionCellClassAdds = (cell, parentRow) => ActionCellEditable(cell, parentRow) ? '' : 'disabledGridCell';

export const ActionCellEditable = (cell, parentRow) => {
    if (cell.field != '(Action)') {
        var isProcessOwner = IsProcessOwner(parentRow);
        var closedActionsAreEditable = Auth.GetAdvancedOption(Auth.AdvancedOption.KEY_CLOSED_ACTIONS_ARE_EDITABLE) || 'N';
        var closedActionsAreDisabled = closedActionsAreEditable !== 'Y';
        var statusChangeRoles = cell.row["StatusChangeRoles"] || '';
        var deleteRoles = cell.row["DeleteRoles"] || '';
        var fullModifyRoles = cell.row["FullModifyRoles"] || '';
        var rightStatusChange, rightDelete, rightFullModify;
        var isActionEmp = ((cell.row["ActionEmployee"] != undefined && cell.row["ActionEmployee"] == Auth.Employee()) 
            || (cell.row["Employee"] != undefined && cell.row["Employee"] == Auth.Employee()));
        var isActionStatusField = cell.field == 'ActionStatusId';
        var readOnlyRow = closedActionsAreDisabled && (cell.row["ActionStatusId"] == '3');

        if (cell.field == 'CommentHTML' || cell.field == 'CommentNoHTML') {
            var editCommentRights = CanEditComment(cell.row['ModUser'], cell.row['Role'], isProcessOwner, false);
            return editCommentRights && !readOnlyRow;
        }
        else {
            rightStatusChange = (statusChangeRoles == '' ? null : Auth.FullRights() || IsUserRoleInRolesList(statusChangeRoles, isActionEmp, isProcessOwner));
            rightDelete = (deleteRoles == '' ? null : Auth.FullRights() || IsUserRoleInRolesList(deleteRoles, isActionEmp, isProcessOwner));
            rightFullModify = (fullModifyRoles == '' ? null : Auth.FullRights() || IsUserRoleInRolesList(fullModifyRoles, isActionEmp, isProcessOwner));

            if (isActionStatusField && rightStatusChange != null)
                return rightStatusChange;

            if (closedActionsAreDisabled || !rightFullModify)
                return !(rightFullModify === false 
                    || (readOnlyRow && (isActionStatusField || (closedActionsAreEditable === 'D' && !Auth.FullRights() && rightFullModify !== true))));
        }
    }
    return true;
}