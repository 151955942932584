import { useState, forwardRef, useRef, useImperativeHandle } from 'react';
import Draggable from 'react-draggable';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import * as ApiProcess from 'api/processes'

import EvsProcessActions from './EvsProcessActions';
import EvsProcessInvoices from './EvsProcessInvoices';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';

function PaperComponent(props) {
    return (        
      <Draggable
        handle=".MuiTypography-subtitle1"
        cancel={'[class*="MuiDialogContent-root"]'}        
      >
        <Paper {...props} />
      </Draggable>      
    );
}

const EvsProcessPopup = forwardRef(({updateactionrow, rowclick, ...props}, ref) => {    
    useImperativeHandle(ref, () => ({         
        handleProcessActionsClick: handleProcessActionsClick,
        processModalOpen: processModalOpen
    }));
        
    const [tabValue, setTabValue] = useState(0);
    const [processModalOpen, setProcessModalOpen] = useState('hidden');
    const handleProcessActionsClose = () => setProcessModalOpen('hidden');
    const [processInfo, setProcessInfo] = useState([]);
    const processActionsGridRef = useGridApiRef();
    const processInvoiesGridRef = useGridApiRef();

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);        
      };

    const handleProcessActionsClick = (id, item) => {             
        setProcessInfo(item);

        ApiProcess.getProcessContacts(item.ProcessId, (data) => { 
            setProcessModalOpen('visible');
            processActionsGridRef.current.loadProcessActions(item, data);
            processInvoiesGridRef.current.loadProcessInvoices(item, data);    
        });      
    }
    
    const handleOnRowClick = (row) => { rowclick(row); }
    const handleUpdateActionRow = (row, contact) => { updateactionrow(row, contact); }
    
    return (
        <PaperComponent
            role="dialog"
            aria-modal="false"
            id="processPopupDialog"            
            variant="outlined"
            tabIndex={-1}                
            sx={{
                position: 'fixed',
                top: `calc(50% - 24vh)`,
                left: `calc(50% - 45vw)`,
                m: 0,
                p: 0,
                borderWidth: 0,
                borderTopWidth: 1,
                zIndex: 1000,
                hideable: true,
                visibility:processModalOpen,
                width: '90vw',
                borderRadius:3,
                boxShadow: 24
            }}
            className={processModalOpen == "hidden" ? "evsHidden" : "" }
            aria-labelledby="draggable-dialog-title"                    
            onClose={handleProcessActionsClose}
            {...props}
        >
            <Typography variant="subtitle1" sx={{ m: 0, p: 2, height:'51px' }}>
                Process: {processInfo.ProcessName}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={handleProcessActionsClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers sx={{ pt:1 }}>
                <Box sx={{ width: '100%', height:'48vh' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', pt:0, mt:0, pb:0, mb:0, minHeight:0 }}>
                        <Tabs value={tabValue} onChange={handleTabChange} sx={{ lineHeight:1, minHeight:0 }} aria-label="process tabs">
                            <Tab label="Invoices" id='tabInvoices' sx={{ p:1, m:0, minHeight:0 }} />
                            <Tab label="Actions" id='tabActions' sx={{ p:1, m:0, minHeight:0 }} />
                        </Tabs>
                    </Box>
                    <Box
                        className={tabValue !== 0 ? "evsHidden" : "" }
                        sx={{ px:3, width:'100%', height:'100%',                     
                            visibility:`${tabValue !== 0 || processModalOpen == 'hidden' ? 'hidden' : 'visible'}`, top:93, left:0, position:'fixed' }}
                    >
                        <EvsProcessInvoices ref={processInvoiesGridRef} />
                    </Box>
                    <Box 
                        className={tabValue !== 1 ? "evsHidden" : "" }
                        sx={{ px:3, width:'100%', height:'100%', 
                            visibility:`${tabValue !== 1 || processModalOpen == 'hidden' ? 'hidden' : 'visible'}`, top:93, left:0, position:'fixed' }}
                    >
                        <EvsProcessActions ref={processActionsGridRef} rowclick={handleOnRowClick} updateactionrow={handleUpdateActionRow} />
                    </Box>
                </Box>
            </DialogContent>
        </PaperComponent>
    );
});

export default EvsProcessPopup;