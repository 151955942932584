import * as Api from 'api/api';

export function getProcessData(processOwner, processIsOpenFilter, funcComplete, funcError) {
    Api.get('processes', {
        'processOwner':         processOwner,
        'processIsOpenFilter':  processIsOpenFilter },    
        (data) => { 
            Api.addId(data, 'ProcessId'); 
            data.forEach(x => {
                x.CreateDate = new Date(x.CreateDate);
            });
            if (funcComplete != undefined) funcComplete(data);
        },
        funcError, true);
}