import axios from 'axios';
import queryString from 'query-string'
import * as Auth from 'utils/auth';

export function getConnection(location, funcComplete, funcError) {
    const { database } = queryString.parse(location.search);
    if (database != undefined)
        Auth.SetUserSetting('database', database); 
    else
        database = Auth.GetUserSetting("database");

    axios.get(process.env.REACT_APP_PUBLIC_API_URL + '/subdomain-settings?database='+database
        ).then(response => {
            Auth.SetUserSetting('tenantId', response.data.tenantId);
            Auth.SetUserSetting('erpIntegrationSettingsId', response.data.erpIntegrationSettingsId);
            if (funcComplete !== undefined) funcComplete(response);
        })
        .catch(err => {
            console.error(err);
            if (funcError !== undefined) funcError(err);
        }
    );
}

export function getToken(formUser, formPassword, funcComplete, funcError) {
    axios.post(process.env.REACT_APP_PUBLIC_API_URL + '/tenants/'+Auth.GetUserSetting('tenantId')
        +'/connections/'+Auth.GetUserSetting('erpIntegrationSettingsId')+'/token',
    { 
        "UserName": formUser,
        "Password": formPassword
    }).then(response => {        
      Auth.SetAccessToken(response.data);
      if (funcComplete !== undefined) funcComplete(response.data);
    }).catch(err => {
      console.error(err);
      if (funcError !== undefined) funcError(err);
    });
}