import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState } from 'react';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

import * as Auth from 'utils/auth';

import { ReactComponent as ARMLogoSmall } from 'img/ARMLogoSmall.svg';
import { ReactComponent as HamburgerClose } from 'img/hamburgerClose.svg';
import { ReactComponent as HamburgerOpen } from 'img/hamburgerOpen.svg';
import { ReactComponent as TaskHubIcon } from 'img/taskHub.svg';
import { ReactComponent as TemplatesIcon } from 'img/templates.svg';
import { ReactComponent as MyActionsIcon } from 'img/myActions.svg';
import { ReactComponent as ProcessesIcon } from 'img/processes.svg';
import { ReactComponent as ClientInvoiceRemindersIcon } from 'img/clientInvoiceReminders.svg';
import { ReactComponent as ClientStatementsIcon } from 'img/clientStatements.svg';
import { ReactComponent as HomeIcon } from 'img/home.svg';
import AccountCircle from '@mui/icons-material/AccountCircle';

const drawerWidth = 85;

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: '#b80000',
  overflowX: 'hidden',
  color: '#fff'
});

const closedMixin = (theme) => ({
  overflowX: 'hidden',
  backgroundColor: '#b80000',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(5)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  backgroundColor: '#e4e4e4',
  height:'35px !important',
  minHeight:'35px !important',
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,  
  backgroundColor:'#e4e4e4',
  height: 50,
  color:'#b80000',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),
  ...(open && {
    marginLeft: 0, /* drawerWidth, */
    /* width: `calc(100% - ${drawerWidth}px)`, */
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({    
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',    
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `0px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  }),
);

const Sidebar = function({ children }) {
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElSettingsMenu, setAnchorElSettingsMenu] = useState(null);
  const [anchorElTaskHubMenu, setAnchorElTaskHubMenu] = useState(null);
  const navigate = useNavigate();
  const isMenuOpenSettings = Boolean(anchorElSettingsMenu);
  const isMenuOpenTaskHub = Boolean(anchorElTaskHubMenu);
  const { pathname } = useLocation();

  const handleDrawerOpenClose = () => { setOpenDrawer(!openDrawer); };

  const handleSettingsMenuOpen = (event) => { setAnchorElSettingsMenu(event.currentTarget); };
  const handleSettingsMenuClose = () => { setAnchorElSettingsMenu(null); };

  const handleTaskHubMenuOpen = (event) => { setAnchorElTaskHubMenu(event.currentTarget); };
  const handleTaskHubMenuClose = () => { setAnchorElTaskHubMenu(null); };

  const handleLogoutClick = () => {
    Auth.ClearLoginInfo();
    navigate('/');
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorElSettingsMenu}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="accountLinksMenu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpenSettings}
      onClose={handleSettingsMenuClose}
      sx={{ marginTop:2.5 }}
    >
      <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>      
    </Menu>
  );

  const renderTaskHubMenu = (
    <Menu
      anchorEl={anchorElTaskHubMenu}
      anchorOrigin={{
        vertical: 0,
        horizontal:(openDrawer ? drawerWidth + 15 : 55),
      }}
      id="taskHubMenu"
      keepMounted
      open={isMenuOpenTaskHub}
      onClose={handleTaskHubMenuClose}
      sx={{ marginTop:2.5 }}
    >
      <Box className="submenuTitle">TaskHub</Box>
      <Link id="linkMyActions" to="/MyActions" onClick={handleTaskHubMenuClose}><MenuItem className={pathname == "/MyActions" ? "submenuItemSelected" : "submenuListItem"}><MyActionsIcon width={openDrawer ? 30 : 24} /> My Actions</MenuItem></Link>
      <Link id="linkProcesses" to="/Main" onClick={handleTaskHubMenuClose}><MenuItem className={pathname == "/Main" ? "submenuItemSelected" : "submenuListItem"}><ProcessesIcon width={openDrawer ? 30 : 24} /> Processes</MenuItem></Link>
      <MenuItem disabled={true}><ClientInvoiceRemindersIcon width={openDrawer ? 30 : 24} /> Client Invoice Reminders</MenuItem>
      <MenuItem disabled={true}><ClientStatementsIcon width={openDrawer ? 30 : 24} /> Client Statements</MenuItem>
    </Menu>
  );

  return (
    <>
      <AppBar id="primaryNavMenu" position="fixed" open={openDrawer}>
        <Toolbar sx={{ marginLeft:-1, marginTop: -1 }}>
          <Box display={openDrawer ? 'display' : 'none'} style={{ marginRight:20, marginTop:5 }}><ARMLogoSmall/></Box>
          <IconButton
            id="btnOpenDrawer"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpenClose}
            edge="start"
            sx={{
              marginRight:2,
              width: 35,
              height: 35
            }}
          >            
            {openDrawer ? <HamburgerClose sx={{ width: 25, height: 25 }} /> : 
              <HamburgerOpen sx={{ width: 25, height: 25 }} />}
          </IconButton>
          <Typography variant="h6" noWrap visibility={openDrawer ? 'hidden' : 'visible'}> 
            A/R Management & Collections 
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Typography variant="h7" noWrap component="div" marginTop={1.4}> 
              { Auth.EmployeeData('EMName') }
            </Typography>
            <IconButton
              id="btnAccountLinksMenu"
              size="50"
              edge="end"
              aria-label="account of current user"
              aria-controls="accountLinksMenu"
              aria-haspopup="true"
              onClick={handleSettingsMenuOpen}
              color="inherit"              
            >
              <AccountCircle sx={{ width:28, height:28 }} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>      
      <Drawer variant="permanent" open={openDrawer}>      
        <Divider />
        <List sx={{ marginTop: 6 }}>
            <ListItem key="Home" disablePadding sx={{ display: 'block' }}>
              <ListItemButton                
                sx={{
                  minHeight: 40,
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  alignContent:'center',                  
                  display:'block',
                  px:0
                }}
                className='menuListItem'
              >
                <ListItemIcon
                  className="menuListItemIcon"
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : 'auto',
                    marginRight:0
                  }}
                >
                  <HomeIcon width={openDrawer ? 30 : 24} />
                </ListItemIcon>
                <Box className="menuListItemText" display={openDrawer ? 'display !important' : 'none'} sx={{ textAlign: 'center' }}>Home</Box>
              </ListItemButton>              
            </ListItem>
            <ListItem key="TaskHub" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                id="linkTaskHub"
                onClick={handleTaskHubMenuOpen}                
                sx={{
                  minHeight: 40,
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  alignContent:'center',
                  px:0,                  
                  display:'block'
                }}                
                className={pathname == "/MyActions" || pathname == "/Main" ? "menuListItemSelected" : "menuListItem"}
              >
                <ListItemIcon
                  className="menuListItemIcon"
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : 'auto',
                    marginRight:0
                  }}
                >
                  <TaskHubIcon width={openDrawer ? 30 : 24} />
                </ListItemIcon>
                <Box className="menuListItemText" display={openDrawer ? 'display !important' : 'none'} sx={{ textAlign: 'center' }}>TaskHub</Box>
              </ListItemButton>              
            </ListItem>
            <ListItem key="Utilities" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                id="linkUtilities"
                sx={{
                  minHeight: 40,
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  alignContent:'center',
                  px:0,                  
                  display:'block'
                }}
                className='menuListItem'
              >
                <ListItemIcon
                  className="menuListItemIcon"
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : 'auto',
                    marginRight:0
                  }}
                >
                  <img width={openDrawer ? 30 : 24} src={require("img/LM_Icon_Util_100.png")} />
                </ListItemIcon>
                <Box className="menuListItemText" display={openDrawer ? 'display !important' : 'none'} sx={{ textAlign: 'center' }}>Utilities</Box>
              </ListItemButton>              
            </ListItem>
            <ListItem key="Templates" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                id="linkTemplates"
                sx={{
                  minHeight: 40,
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  alignContent:'center',
                  px:0,                  
                  display:'block'
                }}
                className='menuListItem'
              >
                <ListItemIcon
                  className="menuListItemIcon"
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : 'auto',
                    marginRight:0
                  }}
                >                  
                  <TemplatesIcon width={openDrawer ? 30 : 24} />
                </ListItemIcon>
                <Box className="menuListItemText" display={openDrawer ? 'display !important' : 'none'} sx={{ textAlign: 'center' }}>Templates</Box>
              </ListItemButton>              
            </ListItem>            
            <ListItem key="Settings" disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                id="linkSettings"
                sx={{
                  minHeight: 40,
                  justifyContent: 'center', 
                  textAlign: 'center', 
                  alignContent:'center',
                  px:0,                  
                  display:'block'
                }}
                className='menuListItem'
              >
                <ListItemIcon
                  className="menuListItemIcon"
                  sx={{
                    minWidth: 0,
                    mr: openDrawer ? 2 : 'auto',
                    marginRight:0
                  }}
                >
                  <img width={openDrawer ? 30 : 24} src={require("img/LM_Icon_Cnfg_100.png")} />
                </ListItemIcon>                                
                <Box className="menuListItemText" display={openDrawer ? 'display !important' : 'none'} sx={{ textAlign: 'center' }}>Settings</Box>
              </ListItemButton>
            </ListItem>          
        </List>
      </Drawer>      
      {renderMenu}
      {renderTaskHubMenu}
      <Main open={openDrawer} sx={{ flex:1, flexGrow:1, minWidth:0, overflowY:'auto' }}>        
        {children}
      </Main>
    </>);  
}

export default Sidebar;