import { forwardRef } from 'react';
import { alpha, styled } from '@mui/material/styles';
import { DataGridPro, gridClasses } from '@mui/x-data-grid-pro';

import * as UserRights from 'utils/user-rights'

const ODD_OPACITY = 0.6;

const EleViaDataGridStyles = styled(DataGridPro)(({ theme }) => ({
  fontSize: '0.75rem',
  [`& .${gridClasses.footerContainer}`]: {
    height:'25pt',
    minHeight: '25pt'
  },  
  [`& .${gridClasses.editInputCell}`]: {     
    fontSize: '0.75rem'
  },  
  [`& .${gridClasses.row}.even`]: {       
    backgroundColor: '#edf1f3',    
    '&:hover': {      
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),            
      '@media (hover: none)': {        
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: '#fcfdff',    
    '&:hover': {      
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }
}));

export const multilineGridStyles = {
  "& .MuiDataGrid-columnHeaderTitle": {
    whiteSpace: "break-spaces",
    lineHeight: "normal",
    textOverflow: "clip !important",
    overflow: "hidden",
  },
  "& .MuiDataGrid-columnHeader--alignRight": { textAlign: 'right' },
  "& .MuiDataGrid-columnHeader": { height: "40px !important", maxHeight: "40px !important" },
  "& .MuiDataGrid-headerFilterRow .MuiSvgIcon-root": { marginTop:"2px !important", marginLeft:"0px !important", paddingLeft:"0px !important" },
  "& .MuiDataGrid-headerFilterRow .MuiIconButton-sizeSmall": { marginTop:"5px !important", marginLeft:"0px !important", paddingLeft:"0px !important" }
}

export const singlelineGridStyles = {
  "& .MuiDataGrid-columnHeader--alignRight": { textAlign: 'right' },
  "& .MuiDataGrid-columnHeader": { height: "30px !important", maxHeight: "30px !important" },
  "& .MuiDataGrid-headerFilterRow .MuiSvgIcon-root": { marginTop:"2px !important", marginLeft:"0px !important", paddingLeft:"0px !important" },
  "& .MuiDataGrid-headerFilterRow .MuiIconButton-sizeSmall": { marginTop:"5px !important", marginLeft:"0px !important", paddingLeft:"0px !important" }
}

export const EvsDataGridInitialState = { pagination: { paginationModel: { pageSize: 100 } } };

const EvsDataGrid = forwardRef(({...props}, ref) => {
  const RowClassName = (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd';
  props.columns.forEach((r) => { 
    r['headerClassName'] = 'hdr' + r.field; 

    var func = (typeof r['cellClassName'] == 'function') ? r['cellClassName'] : null;    

    r['cellClassName'] = (row) => {
      return ((row) => {
        var result = (func != null) ? func(row) : '';
        return result + (' cell' + r.field.replace('(','').replace(')','')) + (' ' + UserRights.AddCellClasses(props['id'], row, props['parentRow']));
      })(row);
    }
  });

  return (        
    <EleViaDataGridStyles
        {...props}
        ref={ref}
        rowHeight={40}
        headerFilterHeight={40}
        columnHeaderHeight={50}
        showColumnVerticalBorder={true}          
        getRowClassName={RowClassName}
        hideFooterSelectedRowCount={true}                
        pageSizeOptions={[ 100 ]}  
        density='compact'
        hideFooter={true}
      />
  )
});

export default EvsDataGrid;