import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import Quill from 'quill';    // Soon to be used

import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';

const EvsQuillEditor = forwardRef(({ readOnly, defaultValue, onTextChange, onSelectionChange, onLoad, ...props }, ref) => {
    const containerRef = useRef();
    const defaultValueRef = useRef(defaultValue);
    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);

    const toolbarOptions = [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],
        ['link', 'image'],
        
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        //[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        //[{ 'direction': 'rtl' }],                         // text direction
               
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        //[{ 'font': [] }],
        [{ 'align': [] }],
        
        ['clean']                                         // remove formatting button
    ];

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
    });

    useEffect(() => {
      ref.current?.enable(!readOnly);
    }, [ref, readOnly]);

    useEffect(() => {        
      const container = containerRef.current;
      const div = container.ownerDocument.createElement('div');      
      const editorContainer = container.appendChild(div);
      const quill = new Quill(editorContainer, {
        theme: 'snow',
        modules: {            
            toolbar: toolbarOptions
        }
      });

      ref.current = quill;
      onLoad(ref.current);

      if (defaultValue != undefined && defaultValueRef.current) {        
        quill.setContents(defaultValueRef.current);
      }

      quill.on(Quill.events.TEXT_CHANGE, (...args) => {
        onTextChangeRef.current?.(...args);
      });

      quill.on(Quill.events.SELECTION_CHANGE, (...args) => {
        onSelectionChangeRef.current?.(...args);
      });

      return () => {
        ref.current = null;
        container.innerHTML = '';
      };
    }, [ref]);

    return <div ref={containerRef} {...props}></div>;
  },
);

EvsQuillEditor.displayName = 'Editor';

export default EvsQuillEditor;