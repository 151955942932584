import * as Auth from 'utils/auth';
import * as Api from 'api/api';

export function getMyActionsData(showActionsInOwnedProcesses, showCompletedActions, minDate, actionsDueBy, funcComplete, funcError) {
    Api.get('user/'+Auth.User()+'/actions', {
        'showActionsInOwnedProcesses':  (showActionsInOwnedProcesses ? 'true' : 'false'),
        'showCompletedActions':         (showCompletedActions ? 'true' : 'false'),
        'minDate':                      minDate,
        'actionsDueBy':                 actionsDueBy },        
        (data) => { 
            Api.addId(data, 'ActionId'); 
            if (funcComplete != undefined) 
                funcComplete(data);
        },
        funcError
    );
}

export function getUserEmployeeInfo(funcComplete, funcError) {
    Api.get('user/'+Auth.User(), null,        
        (data) => { 
            if (data != undefined && data["UserData"] != undefined) {
                Auth.SetUserSettingJson(Auth.Settings.EMPLOYEE_DATA, data.UserData[0]);
                Auth.SetAppConfig(data.ARMConfig[0]);
                Auth.SetLanguageLabels(data.LanguageLabels)
                Auth.SetARMRoles(data.ARMRoles);
            }
            if (funcComplete != undefined) {
                funcComplete(data);
            }
        },
        funcError
    );
}
