import { useState, forwardRef, useRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from 'react-select';

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@mui/material';

import EvsDraggableDialog, { EvsDialogContent } from 'components/core/EvsDraggableDialog';

const EvsMultiSelectPopup = forwardRef(({open, onClose, onSave, filterText, filterCheckedFunc, filterUncheckedFunc, ...props}, ref) => {
    useImperativeHandle(ref, () => ({         
      initialize: initialize
    }));  
    const [defaultValues, setDefaultValues] = useState([]);
    const [titleText, setTitleText] = useState('');
    const [items, setItems] = useState([]);
    const [options, setOptions] = useState([]);
    const selectRef = useRef();

    const handleFilterToggle = (even, val) => {
      if (val)
        setOptions(items.filter(filterCheckedFunc));
      else
        setOptions(items.filter(filterUncheckedFunc));
    }

    const initialize = (value, titleString, allItems) => {
      setItems(allItems);
      if (filterUncheckedFunc != undefined) 
        setOptions(allItems.filter(filterUncheckedFunc));
      else
        setOptions(allItems);
      setTitleText(titleString);
      setDefaultValues(valuesStringToArray(value, allItems));     
    }

    const valuesStringToArray = (valueString, allItems) => {
      var split = valueString.split(',');
      var itemsSelected = [];
      allItems.forEach(x => {
        split.forEach(s => {
          if (s == x.value)
            itemsSelected.push(x);
        })
      });
      return itemsSelected;
    }

    const handleSave = () => { onSave(selectRef.current.props.value); onClose(); }
        
    return (
        <EvsDraggableDialog
          open={open}                     
          aria-labelledby="dialog-title"          
        >
          <EvsDialogContent onClose={onClose} titleText={titleText}>
            <Box sx={{ width:'100%' }}>
              <Select 
                id="multiSelect" 
                ref={selectRef} 
                sx={{ position:'relative' }} 
                height="100%" 
                theme={(theme) => ({
                  ...theme,                    
                  borderRadius:1,
                  colors: { ...theme.colors, primary: 'black' }
                })}
                styles={{
                  container: (base, state) => ({ ...base, border:0 }),
                  valueContainer: (base, state) => ({ ...base, maxHeight:65, overflowY:'auto' }),
                  control: (base, state) => ({ ...base, border: '1px solid #ccc !important', borderColor: '#ccc !important' }),                  
                  menu: (base, state) => ({ ...base, maxHeight:'24vh !important' }),
                  menuList: (base, state) => ({ ...base, maxHeight:'24vh !important' }),
                  option: (base, state) => ([{
                    ...base,
                    paddingTop:0,
                    height:'25px !important',
                    maxHeight:'25px',
                    backgroundColor: state.isFocused || state.isSelected ? '#ffd8d8' : '#fff'
                  }, { ':active': {
                    backgroundColor: !state.isDisabled
                      ? state.isSelected
                        ? '#000'
                        : '#f4d1d1'
                      : undefined,
                  }}])
                }} defaultValue={defaultValues} options={options} isMulti={true} defaultMenuIsOpen={true} closeMenuOnScroll={false} closeMenuOnSelect={false} 
              />
            </Box>
            <Box>
              {filterText != undefined && filterText.length > 0 ? 
                <Typography variant="subtitle1" sx={{ m: 0, pt:1, position: 'absolute', bottom:8, height:'51px' }}>
                  <FormControlLabel control={<Checkbox />} label={filterText} onChange={handleFilterToggle} />                    
                </Typography>
                : <></>
              }
              <IconButton
                  id="btnClose"
                  aria-label="close"
                  onClick={onClose}
                  sx={{
                      position: 'absolute',
                      bottom:8,
                      right:60,
                      color: () => '#cc3333',
                  }}
              >
                  <CancelIcon />                    
              </IconButton>
              <IconButton
                  id="btnSave"
                  aria-label="save"
                  onClick={handleSave}
                  sx={{
                      position: 'absolute',
                      bottom:8,
                      right:12,
                      color: () => '#339933',
                  }}
              >
                  <CheckIcon />
              </IconButton>
            </Box>              
          </EvsDialogContent>
        </EvsDraggableDialog>
    );
});

export default EvsMultiSelectPopup;