import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import { GridActionsCellItem, useGridApiRef } from '@mui/x-data-grid-pro';
import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Typography } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import * as ApiProcess from 'api/processes'
import * as ApiUser from 'api/user'
import * as ApiEmployee from 'api/employee'
import * as ApiConfig from 'api/config'
import * as ApiActions from 'api/actions'
import * as Helpers from 'utils/ui-helpers'
import * as MHelpers from 'utils/mui-helpers'
import * as UserRights from 'utils/user-rights'

import EvsProcessPopup from 'components/EvsProcessPopup'
import EvsDataGrid, { singlelineGridStyles } from 'components/core/EvsDataGrid'
import EvsAccordion, { EvsAccordionSummary, EvsAccordionTitle } from 'components/core/EvsAccordion'

import IconButton from '@mui/material/IconButton';
import MoreIcon from '@mui/icons-material/MoreHoriz';
import Visibility from '@mui/icons-material/Visibility';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import GridView from '@mui/icons-material/GridView';
import { ReactComponent as LightningIcon } from 'img/lightning.svg';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import Quill from 'quill';    // Soon to be used

export default function MyActions() {
  const [actionsGridLoading, setActionsGridLoading] = useState(true);
  const [actionsGridData, setActionsGridData] = useState([]);
  const [editingCell, setEditingCell] = useState(false);
  const [commentsGridLoading, setCommentsGridLoading] = useState(false);
  const [commentsGridData, setCommentsGridData] = useState([]);
  const [processInfo, setProcessInfo] = useState([]);
  const [statusesList, setStatusesList] = useState([]);
  const [customColumnsDefinitions, setCustomColumnsDefinitions] = useState([]);
  const [commentsSelectData, setCommentsSelectData] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [largeSize, setLargeSize] = useState(Helpers.getWindowDimensions().width > 900);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMoreEl, setAnchorMoreEl] = React.useState(null);
  const [rowHover, setRowHover] = React.useState([]);
  const [fieldName, setFieldName] = useState([]);
  const [actionsDueByData, setActionsDueByData] = useState([]);
  const [menuOptionViewAll, setMenuOptionViewAll] = useState(false);
  const [menuOptionShowCompleted, setMenuOptionShowCompleted] = useState(false);
  const [menuOptionActionsDueBy, setMenuOptionActionsDueBy] = useState(Helpers.formatDateToDBString(new Date()));

  const contactsHoverOpen = Boolean(anchorEl);
  const openMoreMenu = Boolean(anchorMoreEl);
  const actionsRef = useGridApiRef();  
  const processModalRef = useRef();    

  const handleStartActionClick = (id) => () => { }

  const loadMyActionsGrid = (viewAll, showCompleted, minDate, actionsDueBy) => {
    setActionsGridLoading(true);

    ApiUser.getMyActionsData(viewAll, showCompleted, '2011-01-01', actionsDueBy, (data) => {
      setActionsGridLoading(false);
      setActionsGridData(data);
      setIsInitialized(true);
    });
  }

  useEffect(() => {
    if (isInitialized) return;
    async function init() {      
      ApiEmployee.getActiveEmployees((data) => { setEmployeesList(data); });
      ApiConfig.getActionStatuses((data) => { setStatusesList(data); });
      ApiConfig.getCustomColumns((data) => { setCustomColumnsDefinitions(MHelpers.setupCustomColumns(data, 'MyActions')); });

      ApiConfig.getActionsDueByList((data) => {
        if (data.length > 0) {
          var firstOptionValue = data[0].value;
          loadMyActionsGrid(menuOptionViewAll, menuOptionShowCompleted, null, firstOptionValue);
          setMenuOptionActionsDueBy(firstOptionValue);
          setActionsDueByData(data);
        }
      });
    }
    init();
  }, [actionsGridData, isInitialized]);

  const handleActionRowClick = (item) => {    
    if (processModalRef.current.processModalOpen == "visible") 
      handleActionRowDoubleClick(item);

    if (processInfo.ProcessId === item.row.ProcessId)
      return;

    async function loadProcessComments(processId) {            
      ApiProcess.getProcessComments(processId, (data) => {
        setCommentsGridLoading(false);
        setCommentsGridData(data);
        setCommentsSelectData(data);
      });
    }

    setCommentsGridLoading(true);
    setProcessInfo(item.row);
    setCommentsGridData([]);
    setCommentsSelectData(null);
    loadProcessComments(item.row.ProcessId);    
  }

  const handleCommentRowClick = (item) => {    
    setCommentsSelectData([ item.row ]);
  }

  const handlePopoverContactOpen = (event) => {
    const field = event.currentTarget.dataset.field;
    const id = event.currentTarget.parentElement.dataset.id;

    if (field == 'ContactId') {
      var row = actionsGridData.filter((r) => r.id == id)[0];
      if (row['Client.Phone'] != undefined || row['Email4'] != undefined) {
        setRowHover(row);
        setAnchorEl(event.currentTarget);
      }
    }
  };  

  const handlePopoverContactClose = () => {    
    setAnchorEl(null);
  };

  const handleActionsMoreClose = () => {
    setAnchorMoreEl(null);
  };

  const handleActionsMoreClick = (event) => {
    event.stopPropagation();
    setAnchorMoreEl(event.currentTarget);
  };

  const handleActionRowDoubleClick = (rowDetails) => {
    if (editingCell) 
      return;
    processModalRef.current.handleProcessActionsClick(rowDetails.id, rowDetails.row);
  }

  const handleChildActionRowClick = (rowDetail) => {
    if (actionsGridData.filter((r) => r.ActionId == rowDetail.row.ActionId).length == 1);
      actionsRef.current.selectRow(rowDetail.row.ActionId, true, true);
  }

  const handleChildActionRowChange = (newActionRow, contact) => {
    var rowModified = actionsGridData.filter(r => r.ActionId == newActionRow.ActionId);
    if (newActionRow != undefined && rowModified.length == 1) {
      if (contact.length > 0)
        rowModified[0]['ContactName'] = contact[0]['ContactName']; 
      Helpers.updateJsonObjectWithObject(newActionRow, rowModified[0]);
    }
  }

  const handleDueByChange = (e) => {
    setMenuOptionActionsDueBy(e.target.value);    
    loadMyActionsGrid(menuOptionViewAll, menuOptionShowCompleted, null, e.target.value);
  }

  const handleMenuViewAll = () => {
    loadMyActionsGrid(!menuOptionViewAll, menuOptionShowCompleted, null, menuOptionActionsDueBy);
    setMenuOptionViewAll(!menuOptionViewAll);
  }

  const handleMenuShowCompleted = () => {    
    loadMyActionsGrid(menuOptionViewAll, !menuOptionShowCompleted, null, menuOptionActionsDueBy);
    setMenuOptionShowCompleted(!menuOptionShowCompleted);
  }

  return ( 
    <>    
    <EvsProcessPopup
      ref={processModalRef}
      rowclick={handleChildActionRowClick}
      updateactionrow={handleChildActionRowChange}      
    />

    <Popover
      sx={{
        pointerEvents: 'none'
      }}
      open={contactsHoverOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      onClose={handlePopoverContactClose}
      disableRestoreFocus
    >
      <Typography sx={{ p: 1, fontSize:'0.75rem' }}>
        {`${rowHover['Client.Phone'] || ''}`}<br/>
        {`${rowHover['EMail4'] || ''}`}</Typography>
    </Popover>

    <Menu
      anchorEl={anchorMoreEl}
      id="myActionMenu"
      open={openMoreMenu}
      onClose={handleActionsMoreClose}
      onClick={handleActionsMoreClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem key="menuOptGridState" id="menuOptGridState" onClick={handleActionsMoreClose} disabled={true}>
        <ListItemIcon key="GridState">
          <GridView fontSize="small" />
        </ListItemIcon>
        Grid State
      </MenuItem>
      <MenuItem key="menuOptViewAll" id="menuOptViewAll" onClick={handleMenuViewAll} selected={menuOptionViewAll}>
        <ListItemIcon key="ViewAll">
          <Visibility fontSize="small" />
        </ListItemIcon>
        View All
      </MenuItem>      
      <MenuItem key="menuOptShowCompleted" id="menuOptShowCompleted" onClick={handleMenuShowCompleted} selected={menuOptionShowCompleted}>
        <ListItemIcon key="ShowCompleted">
          <PlaylistAddCheckIcon fontSize="small" />
        </ListItemIcon>
        Show Completed
      </MenuItem>
    </Menu>

    <Box sx={{ flexGrow:1, pt:7 }}>
      <EvsAccordion id="myActionsAccordion" sx={{ mb:4 }}>
        <EvsAccordionSummary id="myActionsAccordion-header">
          <EvsAccordionTitle id="myActionsAccordion-title" sx={{ flexGrow:1, verticalAlign:'bottom' }}>{Helpers.ML("My Actions")}
            {actionsDueByData != undefined && actionsDueByData.length > 0 ? 
            <Select
              className="menuSelector"            
              fontSize="small"
              labelId="actionsDueByLabel"
              id="actionsDueBy"            
              label="Due By"
              sx={{
                marginLeft:2
              }}
              defaultValue={actionsDueByData[0].value}
              onChange={handleDueByChange}
              onClick={(event) => { event.stopPropagation(); }}
            > 
              {actionsDueByData.map((v, i) => <MenuItem key={v.value} value={v.value}>Due: {v.label}</MenuItem>)}
            </Select> : ''}
          </EvsAccordionTitle>
          <Box sx={{ verticalAlign:'middle', margin:'auto' }}>
            <IconButton            
              label="Menu"            
              color="inherit"
              onClick={handleActionsMoreClick}
            >
              <MoreIcon sx={{ height:24, width:24 }} />
            </IconButton>
          </Box>
        </EvsAccordionSummary>
        <EvsDataGrid
          apiRef={actionsRef}
          sx={{ maxHeight:'45vh', height:'45vh', ...singlelineGridStyles }}
          slots={{ loadingOverlay: LinearProgress }}
          loading={actionsGridLoading}
          onCellClick={(params, event, details) => { setEditingCell(params.field == "ActionStatusId"); }}
          onRowClick={handleActionRowClick}
          onRowDoubleClick={handleActionRowDoubleClick}          
          columns={[ 
            { headerName: 'Action Name', field: 'ActionName', flex:2 },            
            { headerName: '', sortable: false, filterable:false, hideable: false, disableColumnMenu:true, 
                type: 'actions', field:'(Action)', width:30, getActions: ({id, row}) => {
                return [
                  <GridActionsCellItem
                    icon={<LightningIcon width={16} className={(UserRights.MyActionsCellDisabled('(Action)', row) ? 'disabledGridIcon' : '')} />}
                    label="Edit"
                    disabled={UserRights.MyActionsCellDisabled('(Action)', row)}
                    color="inherit"
                    onClick={handleStartActionClick(id)}
                  />
                ];
              } 
            },
            { headerName: 'Status', editable: true, type: 'singleSelect', field: 'ActionStatusId', minWidth:80, valueOptions: statusesList },
            { headerName: 'Employee', field: 'Employee', flex:.8, type: 'singleSelect', valueOptions: ({ row }) => { return employeesList; }, valueGetter: (v) => (v || '<none>') },
            { headerName: 'Contact', field: 'ContactId', flex:.8, valueGetter: (v, r) => r["ContactName"] != undefined ? r["ContactName"] : (r["LastName4"] && (r["LastName4"] + ', ' + r["FirstName4"])), desciption:"" },
            { headerName: 'Process Name', field: 'ProcessName', flex:1.5 },
            { headerName: 'Project', field: 'WBS1', flex:.5 },
            { headerName: 'Date Scheduled', field: 'DateScheduled', flex:.5, type:'date', ...MHelpers.dateFormatting() },
            ...customColumnsDefinitions
          ]}          
          initialState={{
            columns: {
              columnVisibilityModel: {
                "WBS1": largeSize,
                "ProcessName": largeSize,
                "ContactId": largeSize,
                "ActionStatusId": largeSize
              }
            }
          }}
          slotProps={{
            cell: {
              onMouseEnter: handlePopoverContactOpen,
              onMouseLeave: handlePopoverContactClose
            }
          }}
          rows={actionsGridData}               
          headerFilters={largeSize}
          onCellEditStop={(params, event) => {
            setFieldName(params.field);
          }}
          processRowUpdate={(updatedRow, originalRow) => {
            ApiActions.updateActionField(updatedRow.ActionId, fieldName, updatedRow[fieldName], '');
            return updatedRow;
          }}
        />
      </EvsAccordion>      

      <EvsAccordion id="commentsAccordion" sx={{ flexGrow:1 }}>
        <EvsAccordionSummary id="commentsAccordion-header">
          <EvsAccordionTitle id="commentsAccordion-title" sx={{ flexGrow:1 }}>Comments</EvsAccordionTitle>
        </EvsAccordionSummary>
        <Box display="flex" sx={{ flexDirection:'row', width:'100%', p:0 }}>
          <Box sx={{ width:.6 }} paddingRight={1}>
            <EvsDataGrid
              sx={{ maxHeight:'28vh', height:'28vh' }}
              slots={{ loadingOverlay: LinearProgress }}
              loading={commentsGridLoading}
              onRowClick={handleCommentRowClick}
              columns={[ 
                { headerName: 'Modified', field: 'ModDate', flex:1, type:'date', ...MHelpers.dateTimeFormatting() },
                { headerName: 'Modified By', field: 'ModEmp', flex:1.2 },
                { headerName: 'Action', field: 'ActionName', flex:2 },
                { headerName: 'Invoice', field: 'Invoice', flex:.8 },
                { headerName: 'Project', field: 'WBS1', flex:.7 },
                { headerName: 'Comment', field: 'CommentNoHTML', flex:2 }
              ]}          
              rows={commentsGridData}
              initialState={{
                columns: {
                  columnVisibilityModel: {
                    "ModDate": largeSize,
                    "ActionName": largeSize,
                    "Invoice": largeSize,
                    "WBS1": largeSize
                  }
                }
              }}
            />            
          </Box>
          <Box display="flex" sx={{ width:.4, maxHeight:'28vh', height:'28vh', overflowY:'auto', fontSize:'10pt', flexDirection:'column' }}>
              {commentsSelectData?.length > 0 && commentsSelectData.map((v, i) => {
                return (
                  <div key={i}>
                    <div style={i > 0 ? { borderTop:'1px solid #000000', marginTop:5, paddingTop:5, width:'100%' }
                        : { paddingTop:5, width:'100%' }}>
                      <b>{(v.Invoice || '') != '' ? 'INVOICE: ' + v.Invoice : ''} 
                      {(v.ActionName || '') != '' ? 'ACTION: ' + v.ActionName : ''} 
                      {(v.ActionName || '') == '' && (v.Invoice || '') == '' ? 'PROCESS COMMENT' : ''} </b>
                      &nbsp;(Last modified {Helpers.formatDateTime(v.ModDate)} by {v.ModEmp})
                    </div>
                    <div style={{ paddingTop:5, clear:'left', width:'100%' }} dangerouslySetInnerHTML={{__html: v.CommentHTML}}></div>
                  </div>)
              })}
          </Box>
        </Box>
      </EvsAccordion>
    </Box>
    </>
  );
}