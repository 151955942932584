import 'App.css';
import { Routes, Route, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LicenseInfo } from '@mui/x-license'

import AuthRoute, { IsAuthenticated } from 'utils/auth';

import Login from 'pages/login/Login';
import PageNotFound from 'pages/PageNotFound';
import Main from 'pages/main/Main';
import MyActions from 'pages/my-actions/MyActions';
import Sidebar from 'pages/Sidebar';
import loginBackground from "img/login-background.png";

const theme = createTheme({  
  palette: {
    primary: {
      main: '#979a9b',
    },
    secondary: {
      main: '#f44336',
    }
  }
});

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/Main" element={<AuthRoute><Main /></AuthRoute>} />
      <Route path="/MyActions" element={<AuthRoute><MyActions /></AuthRoute>} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

function App() {
  const location = useLocation();   
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY);

  return (    
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Box 
        position="fixed" 
        sx={{ display: 'flex', flex:1, flexGrow:1, width:'100%', height:'100%', 
          backgroundSize: '100% 100%',          
          backgroundColor: IsAuthenticated() ? '' : 'rgba(0,0,0,1)',
          backgroundImage: IsAuthenticated() ? '' : `url(${loginBackground})` }}
      >
        { IsAuthenticated() && location.pathname !== '/' ? 
          <>
            <Sidebar>
              <AppRoutes />
            </Sidebar>
          </>
            : 
          <AppRoutes />
        }
      </Box>
    </ThemeProvider>
  );
}

export default App;
