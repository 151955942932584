import { useMemo } from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';

import EvsDataGrid, { multilineGridStyles } from 'components/core/EvsDataGrid'
import { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import EvsCommentEditPopup from 'components/EvsCommentEditPopup'

import * as ApiProcess from 'api/processes'
import * as ApiConfig from 'api/config'
import * as Auth from 'utils/auth';
import * as UserRights from 'utils/user-rights'

import { formatCurrency, removeHtml, getWindowDimensions } from 'utils/ui-helpers'
import * as MHelpers from 'utils/mui-helpers'
import * as Helpers from 'utils/ui-helpers'

const EvsProcessInvoices = forwardRef(({...props}, ref) => {
    useImperativeHandle(ref, () => ({         
        loadProcessInvoices: loadProcessInvoices,
        setProcessInvoicesGridLoading: setProcessInvoicesGridLoading
    }));

    const [largeSize, setLargeSize] = useState(getWindowDimensions().width > 900);
    const [processInvoicesGridLoading, setProcessInvoicesGridLoading] = useState(true);
    const [processInvoicesGridData, setProcessInvoicesGridData] = useState([]);
    const [contactsList, setContactsList] = useState([]);
    const [rootCausesList, setRootCausesList] = useState([]);
    const [fieldName, setFieldName] = useState([]);
    const [popupContext, setPopupContext] = useState([]);
    const [parentRow, setParentRow] = useState([]);
    const [customColumnsDefinitions, setCustomColumnsDefinitions] = useState([]);
    const [editInvoiceCommentsVisible, setEditInvoiceCommentsVisible] = useState(false);

    const editInvoiceCommentsRef = useRef();

    async function loadProcessInvoices(newParentRow, contactsListFromParent) {
        if (newParentRow.ProcessId === parentRow.ProcessId) 
            return;
        
        setParentRow(newParentRow);
        setProcessInvoicesGridLoading(true);
        setProcessInvoicesGridData([]);
        setContactsList(contactsListFromParent);
        ApiConfig.getCustomColumns((data) => { setCustomColumnsDefinitions(MHelpers.setupCustomColumns(data, 'Invoice')); });

        ApiConfig.getRootCauses((data) => {
            setRootCausesList(data);

            ApiProcess.getProcessInvoices(newParentRow.ProcessId, (data) => {   
                setProcessInvoicesGridLoading(false);
                setProcessInvoicesGridData(data);            
            });    
        });
    }

    const handleClickInvoiceComments = (row) => () => {
        setEditInvoiceCommentsVisible(true);
        setPopupContext(row);
        editInvoiceCommentsRef.current.initialize(row?.CommentHTML || '', `Comments for Invoice ${row.Invoice} and Project ${row.WBS1}`, row);
    }
    const handleSaveInvoiceComments = (comment, invoiceComments, rowsDeleted) => {
        var commentsCsv = '', i = 0, lastCommentId = 0, lastCommentHtml = '';
        invoiceComments.forEach(x => {
            if (x.modified === true) {
                i++;
                if (i > 1) commentsCsv += ', ';
                commentsCsv += `(${i}, ${x.CommentId}, '${x.CommentHTML.replace(/'/, '\'\'')}')`;
            }
            
            if (x.ModUser == Auth.User()) {
                lastCommentId = x.CommentId;
                lastCommentHtml = x.CommentHTML;
            }
        });
        rowsDeleted.forEach(x => {
            i++;
            if (i > 1) commentsCsv += ', ';
            commentsCsv += `(${i}, ${x}, null)`;
        });

        if (i > 0) {
            ApiProcess.upsertProcessComments(parentRow.ProcessId, null, popupContext.Invoice, popupContext.WBS1, null, null, commentsCsv, (data) => {
                if (data != undefined && data.LastCommentId != undefined) {
                    popupContext.CommentId = data.LastCommentId;
                }
            }); 
            popupContext.CommentId = lastCommentId;
            popupContext.CommentHTML = lastCommentHtml;
            popupContext.CommentNoHTML = lastCommentHtml;
        }
    }

    const InvoiceColumns = useMemo(
        () => [ 
            { headerName: 'Invoice', field: 'Invoice', width:70 },
            { headerName: 'Project', field: 'WBS1', width:90 },                
            { headerName: 'Project Name', field: 'ProjectName', width:300 },
            { headerName: 'Invoice Date', field: 'InvoiceDate', minWidth:80, type:'date', valueGetter: MHelpers.dateValueFormatter },
            { headerName: 'Payment Terms', field: 'PaymentTerms', headerAlign:'left', width:70, type:'number' },
            { headerName: 'Days Old', field: 'DaysOld', type:'number', headerAlign:'right', align:'right', minWidth:60 },
            { headerName: 'Days Overdue', field: 'DaysOverdue', type:'number', headerAlign:'right', align:'right', minWidth:60 },
            { headerName: 'Invoice Amount', field: 'InvoiceAmount', type:'number', headerAlign:'right', align:'right', valueFormatter: (v, r) => v && formatCurrency(v), minWidth:90 },
            { headerName: 'Invoice Balance', field: 'InvoiceBalance', type:'number', headerAlign:'right', align:'right', 
                valueFormatter: (v, r) => v && formatCurrency(v), minWidth:90, cellClassName: (r) => ((r.value > 0) ? "cellUnpaidInvoice" : "cellPaidInvoice")
            },
            { headerName: 'Anticipated Pay Date', field: 'AnticipatedPayDate', editable: true, minWidth:80, type:'date', valueGetter: MHelpers.dateValueFormatter },
            { headerName: 'Promise To Pay Date', field: 'PromiseToPayDate', editable: true, minWidth:80, type:'date', valueGetter: MHelpers.dateValueFormatter },
            { headerName: 'Promise To Pay By Whom', field: 'PromiseToPayByWhomId', editable: true, minWidth:80, type: 'singleSelect', valueOptions: contactsList, valueGetter: (v) => (v || '') },                
            { headerName: 'Root Cause', field: 'RootCause', editable: true, width:100, type: 'singleSelect', valueOptions: rootCausesList, valueGetter: (v) => (v*1 || '<none>') },
            { headerName: 'Comments', field: 'CommentHTML', width:300, renderCell: (params) => {
                var enabled = UserRights.InvoiceCellEditable({ field: 'CommentHTML', row: params.row }, parentRow);                
                return (<div>
                        <IconButton className={'btnInvoiceComments' + (!enabled ? ' disabledGridIcon' : '')} sx={{ fontSize:'1em !important', padding:0, color:'#000000 !important' }} 
                            aria-label="Edit Invoice Comments" disabled={!enabled} onClick={handleClickInvoiceComments(params.row)}>
                                {(params.value || '') != '' ? <div>{Helpers.removeHtml(params.value)}</div> : 
                            <CommentIcon sx={{ color:"Gray", fontSize:14 }} />}
                        </IconButton>
                    </div>) 
                }
            },
            ...customColumnsDefinitions
        ]
    );

    return (
        <>
        <EvsCommentEditPopup 
            ref={editInvoiceCommentsRef}
            title="Edit Invoice Comments"
            open={editInvoiceCommentsVisible} 
            onSave={handleSaveInvoiceComments}
            onClose={() => setEditInvoiceCommentsVisible(false)} 
            filterText=""
        />
        <EvsDataGrid
            id="invoicesGrid"
            parentRow={parentRow}
            slots={{ loadingOverlay: LinearProgress }}
            headerFilters={largeSize}
            loading={processInvoicesGridLoading}
            sx={{
                height:'43vh',                
                mb:1,
                p:0,
                ...multilineGridStyles
            }}            
            columns={InvoiceColumns}
            initialState={{
                columns: {
                  columnVisibilityModel: {
                    "ProjectName": largeSize,
                    "PaymentTerms": largeSize,
                    "DaysOverdue": largeSize,
                    "InvoiceAmount": largeSize,
                    "PromiseToPayDate": largeSize,
                    "PromiseToPayByWhomId": largeSize,
                    "RootCause": largeSize
                  }
                }
            }}
            onCellEditStop={(params, event) => {
                setFieldName(params.field);
            }}
            processRowUpdate={(updatedRow, originalRow) => {
                ApiProcess.updateProcessInvoiceField(updatedRow.ProcessId, updatedRow.Invoice, updatedRow.WBS1, fieldName, updatedRow[fieldName]);
                return updatedRow;
            }}
            onProcessRowUpdateError={(error) => { console.log(error); }}
            rows={processInvoicesGridData}
            {...props}
        />
        </>
    );
});

export default EvsProcessInvoices;